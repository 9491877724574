import React from 'react'
import { useCallback } from 'react'
import { ElementsUIGenrate } from './ElementsUIGenrate'
import { gql, useQuery } from '@apollo/client'
// import { useState } from 'react'
import { useMutation } from '@apollo/client'

const Formnames = gql`query GetFormsByFormFieldId($fieldId: String) {
  GetFormsByFormFieldId(field_id: $fieldId) {
    form_id
    form_name
  }
}`

const DeleteCustomField = gql`
mutation DeleteFormField($fieldId: String) {
  DeleteFormField(field_id: $fieldId) {
    field_id
  }
}`

export const CustomeElementDeleteModal = ({ CustomEleDeleteId, setCustomEleDeleteId }) => {
    // const [deleteCustomField, setDeleteCustomField] = useState(null);

//console.log("CustomEleDeleteId", CustomEleDeleteId);
const { data:Formname, loading, error } = useQuery(Formnames, {
    variables: {
        fieldId: CustomEleDeleteId?.elementid
    },
    // skip: !CustomEleDeleteId
});

const [deleteField] = useMutation(DeleteCustomField, {
    onCompleted: () => {
        setCustomEleDeleteId(null);
        CustomEleDeleteId?.refetchform();
    }
});


    const Element = useCallback(({ id,name }) => {

        const partBeforeDash = id.split('-')[0];
        const Store = ElementsUIGenrate(partBeforeDash);
       
        //console.log("Store>", id,name);
        return (
            <div
                className="d-flex p-2 my-2 border rounded align-items-center gap-2 disable-default-field w-50"
                id={id}
            >
                <i className="fs-3 iconify" data-icon={Store?.icon} />
                <span>{name}</span>
                <i
                    className="small iconify ms-auto text-primary"
                    data-icon="fa6-solid:cube"
                />
            </div>
        );
    }, []);

    const renderFormsList = () => {
        if (loading) {
            return <p className="text-secondary">Loading...</p>;
        }

        if (error) {
            return <p className="text-danger">Error loading forms</p>;
        }

        if (!Formname?.GetFormsByFormFieldId.length) {
            return <p className="text-secondary">Not used in any forms</p>;
        }

        return Formname.GetFormsByFormFieldId.map((item) => (
            <a
                key={item.form_id}
                className="d-flex justify-content-between align-items-center pointer w-100 text-decoration-none text-dark border-top py-3 pointer"
            >
                <span>{item.form_name}</span>
                <i className="iconify" data-icon="mdi:link" />
            </a>
        ));
    };

    const handleCustomFieldDelete = (fieldId) => {
        deleteField({
            variables: {
                fieldId
            }
        });

    }

  return (
      <div
          className="modal fade"
          id="CustomFieldDeleteModal"
          data-bs-backdrop="true"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        //   className="btn btn-secondary btn-sm px-4 rounded-pill"
      >
          <div className="modal-dialog modal-md ">
              <div className="modal-content border-0 h-100">
                  <div className="modal-body alert-dadnger rounded-3 py-4 px-md-4 px-3">
                      <div>
                          <i className="iconify mb-3 fs-1" data-icon="twemoji:warning" /> 
                         {CustomEleDeleteId == null ? null : Element({ id: CustomEleDeleteId?.elementid, name: CustomEleDeleteId?.name})}
                      </div>
                      <h3>You cant Delete this custom field</h3>
                      <p className="small text-secondary">
                          To delete this custom field you would need to remove them from all
                          forms in which there are being used separately{" "}
                      </p>
                      <p className="mt-5">
                          List of forms where custom field <span className='font-weight-bold'>{CustomEleDeleteId?.name}</span> is being used
                      </p>
                      <div className="py-3 d-flex flex-column">
                        {
                            renderFormsList()
                        }
                          {/* <a className="d-flex justify-content-between align-items-center pointer w-100 text-decoration-none text-dark border-top py-3 pointer">
                              <span>Terminate form</span>
                              <i className="iconify" data-icon="mdi:link" />
                          </a>
                          <a className="d-flex justify-content-between align-items-center pointer w-100 text-decoration-none text-dark border-top py-3 pointer">
                              <span>Dissposition Form Whissel Do Not Touch</span>
                              <i className="iconify" data-icon="mdi:link" />
                          </a>
                          <a className="d-flex justify-content-between align-items-center pointer w-100 text-decoration-none text-dark border-top py-3 pointer">
                              <span>Transaction Template Do Not Touch</span>
                              <i className="iconify" data-icon="mdi:link" />
                          </a> */}
                      </div>
                      <div className="d-flex justify-content-end gap-3 pt-3">
                          <button
                              type="button"
                              className="btn btn-danger btn-sm px-4 rounded-pill"
                              data-bs-dismiss="modal"
                              onClick={() => handleCustomFieldDelete(CustomEleDeleteId?.elementid, CustomEleDeleteId?.refetchform)}
                          >
                              Delete
                          </button>
                          <button
                              type="button"
                              className="btn btn-secondary btn-sm px-4 rounded-pill"
                              data-bs-dismiss="modal"
                              onClick={() => setCustomEleDeleteId(null)}
                          >
                              Cancel
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  )
}

 import React from "react";
import WelcomeImg from "../../assets/images/opp-group-2.png";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Loaderr } from "../../components/Loaderr";

const GetOppPipelineGroups = gql`
  query GetOppPipelineGroups {
    GetOppPipelineGroups {
      opp_group_id
      opp_group_name
      pipeline_ids
      team_id
    }
  }
`;

const OppCreationSuccess = () => {
  const navigate = useNavigate();
  const {data, loading} = useQuery(GetOppPipelineGroups);
  if (loading) return <Loaderr />;
  return (
    <section className="d-flex justify-content-center">
      <div className="d-flex flex-wrap flex-md-nowrap  flex-lg-nowrap align-items-center justify-content-between col-xl-11 col-xxl-9 gap-5 mt-5">
        <div className="d-flex flex-column">
          <span className="text-secondary small mb-4">
            Setup your Opportunity table
          </span>
          <h5
            className="mb-2 d-flex align-items-center gap-2"
            style={{ lineHeight: "1.5" }}
          >
            <i
              className="iconify fs-3 text-success"
              data-icon="material-symbols:check-circle"
            ></i>{" "}
            
            {data?.GetOppPipelineGroups[0]?.opp_group_name} - <span className="fw-light">opportunity</span>
          </h5>
          <p className="text-secondary">
            Your opportunity type has been successfully created! You can easily
            add more groups later in settings tab. Now, let’s dive into your
            opportunity table.
          </p>
          <h5
            className="d-flex align-items-center gap-3 text-decoration-underline mt-4"
            onClick={() => navigate("/opportunities/")}
          >
            Let's Go
            <i className="iconify fs-3" data-icon="tabler-arrow-right"></i>
          </h5>
        </div>

        <img src={WelcomeImg} alt="" />
      </div>
    </section>
  );
};

export default OppCreationSuccess;

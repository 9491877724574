import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { Loaderr } from "../../../components/Loaderr";
import { OppsNavBar } from "../../../components/HeadNavBar";
import '../../../assets/css/opportunityList.css';

export default function ConversionRatio() {
  const navigate = useNavigate();
  const { Opps } = useFormApiHelper();
  const { oppsLoading, oppsError, opps } = Opps();
  const handleEntryView = (oppid) => {
    navigate(`/opportunity/${oppid}`);
  };
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOpps = opps?.Opps.filter((opp) =>
    Object.values(opp).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );


  const totalOppPrice = opps?.Opps?.reduce((accumulator, opp) => {
    if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price)) {
      return parseFloat((opp.opp_price + accumulator).toFixed(2));
    } else {
      return accumulator;
    }
  }, 0);

  const totalCgi = opps?.Opps?.reduce((accumulator, opp) => {
    if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price) &&
        typeof opp.opp_commission_percent === 'number' && !isNaN(opp.opp_commission_percent)) {
      const totalPriceWithCommission = opp.opp_price * (opp.opp_commission_percent );
      return parseFloat((totalPriceWithCommission + accumulator).toFixed(2));
    } else {
      return accumulator;
    }
  }, 0);
  

  function currencyConvert(totalOppPrice) {
    if (totalOppPrice >= 1000000000) {
      return (totalOppPrice / 1000000000).toFixed(2) + ' B';
    } else if (totalOppPrice >= 1000000) {
      return (totalOppPrice / 1000000).toFixed(2) + ' M';
    } else {
      return totalOppPrice.toString();
    }
  }
  



  if (oppsLoading) return <Loaderr />;
  return (
    <>
      <OppsNavBar />
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">

          <section className="d-flex flex-column py-4 gap-3">

            
            <h5 class="m-0 fs-5 fw-bold mb-3">% Conversion Ratio</h5>
          
            
    {/* <div class="main body-pd" id="body-pd"> */}

        <div class="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0" role="alert">
            <div class="d-flex flex-wrap align-items-center">
    
                <div class="d-flex align-items-center my-2">
                    <i class="bx bx-mobile fs-5"></i>
                    <i class="bx bx-arrow-to-right me-2 fs-5"></i>
                    <i class="bx bx-desktop me-2 fs-5"></i>
                </div>
                
                <span class="my-2">Please switch to desktop and optimize this view. Smaller screens are not efficient.</span>
                
                
            </div>
        </div>
       
        {/* <div class="content"> */}



            <h5 class="m-0 fs-5 fw-bold mb-3"></h5>

            <nav class="d-flex my-4">
                <div class="cr-tabs nav nav-tabs border rounded-pill bg-white flex-nowrap fi-tab" role="tablist">
                    <button class="nav-link mb-0 text-dark px-4 active" id="1" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd"><i class="iconify me-1" data-icon="mdi:hourglass"></i>YTD</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-4" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd"><i class="iconify me-1" data-icon="mdi:calendar"></i>YEAR</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-4" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd"><i class="iconify me-1" data-icon="mdi:calendar-month"></i>Month</span>
                    </button>

                </div>
            </nav>


            <nav class="d-flex">
                <div class="cr-tabs cr-long-tabs nav nav-tabs border rounded-pill bg-white flex-nowrap fi-tab" role="tablist">
                    <button class="nav-link mb-0 text-dark px-4 active" id="1" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">JAN</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">FEB</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">MAR</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">APR</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">MAY</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">JUN</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">JUL</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">AUG</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">SEP</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">OCT</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">NOV</span>
                    </button>

                    <button class="nav-link mb-0 text-dark px-3" id="2" data-bs-toggle="tab" data-bs-target="" type="button" role="tab" >
                        <span class="fw-boldd">DEC</span>
                    </button>

                </div>
            </nav>


            <div class="row row-cols-md-4 row-cols-xxl-5 pt-5 gy-4 flex-nowrap overflow-scroll">

                <div>
                    <div class="bg-white border rounded-3 py-3 px-4 text-center">

                        <span class="text-primary fw-bold fs-small">Total calls</span>
                        <h5>1223</h5>
                        <span class="small text-secondary">YTD</span>
    
                    </div>
                </div>

                <div>
                    <div class="bg-white border rounded-3 py-3 px-4 text-center">

                        <span class="text-primary fw-bold fs-small">Total Volume</span>
                        <h5>$ 2.094 M</h5>
                        <span class="small text-secondary">$2,094,099.00</span>
    
                    </div>
                </div>

                <div>
                    <div class="bg-white border rounded-3 py-3 px-4 text-center">

                        <span class="text-primary fw-bold fs-small">Total Email Sent</span>
                        <h5>456</h5>
                        <span class="small text-secondary">YTD</span>
    
                    </div>
                </div>

                <div>
                    <div class="bg-white border rounded-3 py-3 px-4 text-center">

                        <span class="text-primary fw-bold fs-small">Net Income</span>
                        <h5>$ 1.24 M</h5>
                        <span class="small text-secondary">$1,240,099.00</span>
    
                    </div>
                </div>
                
                
            </div>

            <div class="d-flex align-items-center pointer text-secondary mt-3 pointer" data-bs-toggle="modal" data-bs-target="#customise-summary">
                <i class='iconify fs-5 me-2' data-icon="mdi:cog"></i>
                <span class="text-decoration-underline ">customise summary</span> 
            </div>
            <div class="table-responsive opp-table pe-1">
              <table class="table">
                <thead>
                  <tr>
                    {/* <th scope="col"><input class="form-check-input" type="checkbox"></th> */}
                    {/* <th scope="col">Action</th> */}

                    {/* ‘opp_stage’, ‘opp_agreement_signed_date’, ‘opp_type’, ‘opp_appt_date’, ‘opp_appt_met_date’, ‘fub_person_id’, ‘opp_price’, ‘sisu_client_id’, ‘opp_agreement_expiration_date’, ‘opp_commission_percent’, ‘opp_postal_code’, ‘opp_last_name’, ‘opp_state’, ‘opp_forecasted_close_date’, ‘opp_under_contract_date’, ‘opp_settlement_date’, */}

                  
                    <th scope="col">Agent Names</th>
                    <th scope="col">Appointment Assigned</th>
                    <th scope="col">Appointment Showed</th>
                    <th scope="col">Show Up Rate</th>
                    <th scope="col">Signed</th>
                    <th scope="col">Signed Ratio</th>
                    <th scope="col">Closed</th>
                    <th scope="col">Closed Ratio</th>
                    {/* <th scope="col">Agreement Expiration Date</th>
                    <th scope="col">Commission Percent</th>
                    <th scope="col">Postal Code</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">State</th>
                    <th scope="col">Forecasted Close Date</th>
                    <th scope="col">Under Contract Date</th>
                    <th scope="col">Settlement Date</th> */}


                  </tr>
                </thead>
                <tbody>
                  {
                    filteredOpps.map(
                      (opp, index) => (
                        (
                          <tr>

                            */}

                            {/* <td> <i class='iconify fs-5 pointer' data-icon="dashicons:ellipsis"></i></td> */}
                            <td>{opp?.opp_stage}</td>
                            <td>{opp?.opp_agreement_signed_date}</td>
                            <td>{opp?.opp_type}</td>
                            <td>{opp?.opp_appt_date}</td>
                            <td>{opp?.opp_appt_met_date}</td>
                            <td>{opp?.fub_person_id}</td>
                            <td>{opp?.opp_price}</td>
                            <td>{opp?.sisu_client_id}</td>
                            {/* <td>{opp?.opp_agreement_expiration_date}</td>
                            <td>{opp?.opp_commission_percent}</td>
                            <td>{opp?.opp_postal_code}</td>
                            <td>{opp?.opp_last_name}</td>
                            <td>{opp?.opp_state}</td>
                            <td>{opp?.opp_forecasted_close_date}</td>
                            <td>{opp?.opp_under_contract_date}</td>
                            <td>{opp?.opp_settlement_date}</td> */}

                          </tr>
                        )
                      )
                    )
                  }


                </tbody>
              </table>

              <div></div>
            </div>


        {/* </div> */}

    {/* </div> */}

            {/* modal */}
           {/* modal */}
            {/* <div class="row row-cols-3 py-5">

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total Calls</span>
                  <h2>{opps?.Opps?.length}</h2>
                  <span class="small text-secondary">{opps?.Opps?.length} after split Counts</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total Volume</span>
                  <h2>$ {currencyConvert(totalOppPrice)}</h2>
                  <span class="small text-secondary">{totalOppPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total Emails Sent</span>
                  <h2>$ {currencyConvert(totalCgi)}</h2>
                  <span class="small text-secondary">{totalCgi.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Net Income</span>
                  <h2>$ {currencyConvert(totalCgi)}</h2>
                  <span class="small text-secondary">{totalCgi.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
            </div> */}
            
          </section>

        </section>
      </div>
    </>
  );
}

import React, { useEffect ,useState} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { gql, useQuery } from '@apollo/client';
import logo from "../../../assets/images/interface_logo.png"
import "../../../assets/css/ThankyouPage.css"
import { Loaderr } from "../../../components/Loaderr";

// import Confetti from 'react-confetti'
const FormData = gql`
query FormData($formId: String) {
formData(form_id: $formId) {
form_id
team_id
form_name
form_description
form_fields
form_conditions
status
form_created_date
form_updated_date
update_by
base_form_template
color_scheme
logo_url
form_type
pipeline
stage
is_this_appt_form
disp_text
select_disp_form
disp_sched_hour
disp_sched_minutes
disp_reminder_hour
disp_reminder_minutes
make_reminder_rec
lead_form_redirect_setting
lead_form_redirect_url
redirect_page_timeout
thank_you_page_message
}
}
`;

export default function ThankYouPageForLeadForms() {
  const [timer, setTimer] = React.useState(15);
  const [showTimer, setShowTimer] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);
  const [hostName, setHostName] = React.useState("");
  const navigate=useNavigate()
  const [message, setMessage] = React.useState("");


  const { data, loading, error } = useQuery(FormData, {
    variables: { formId: getURLParams.get("formId") },
    });
console.log("Data",data)
  useEffect(()=>{
    if(data){
        if(data.formData.lead_form_redirect_setting === "inline_page"){
            const timeer = data.formData.redirect_page_timeout
            setShowTimer(timeer)
            setTimer(20)
            setMessage(data.formData.thank_you_page_message)

    }else  if(data.formData.lead_form_redirect_setting === "another_page"){
        const interFaceListUrl=data.formData.lead_form_redirect_url;
        window.location.replace(interFaceListUrl);

    }
   
    }

  },[data])

  const get_hostname_forLeadForm = () => {
    let url = window.location.host
    
   if(getURLParams.get("context")){
    let hostname = ""
    if (url === "app.sandbox.datalabz.re") {
      hostname = `https://app.sandbox.datalabz.re/list-view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
    }
    else if (url === "app.test.datalabz.re") {
      hostname = `https://app.test.datalabz.re/list-view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
    }
    else if (url === "app.datalabz.re") {
      hostname = `https://app.datalabz.re/list-view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
    }
    else {
      hostname = `http://localhost:3000/list-view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
    }
    return hostname;
   }else{
    let hostname = ""
    if (url === "app.sandbox.datalabz.re") {
      hostname = `https://app.sandbox.datalabz.re/forms/${getURLParams.get("formId")}`

    }else if (url === "app.test.datalabz.re") {
      hostname = `https://app.test.datalabz.re/forms/${getURLParams.get("formId")}`
    }else if (url === "app.datalabz.re") {
      hostname = `https://app.datalabz.re/forms/${getURLParams.get("formId")}`
    }else {
      hostname = `http://localhost:3000/forms/${getURLParams.get("formId")}`
    }
    return hostname;
   }
  }
 


  useEffect(() => {
    if (!showTimer) return;  // Only run if the timer is enabled
    
    const countdown = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);
  
    const iconInterval = setInterval(() => {
      setShowIcon(prevState => !prevState);  // Toggle the icon state
    }, 4000);
  
    // Check if the timer reaches 0, then redirect
    if (timer === 0) {
      const interFaceListUrl = get_hostname_forLeadForm();
      window.location.replace(interFaceListUrl);
    }
  
    return () => {
      clearInterval(countdown); // Clean up intervals on unmount
      clearInterval(iconInterval);
    };
  }, [timer, showTimer]);
if (loading) return <Loaderr />;
return (
  <>
  
  <div
  id="master-wrap"
  className="p-4 d-flex flex-column align-items-center text-center justify-content-center h-100"
>
  <div id="logo-box">
    <div className='d-flex flex-column align-items-center'>
      <i className=" w-100 iconify text-primary mb-5" style={{fontSize:'150px'}} data-icon="line-md:check-list-3" ></i>
      <h2 className="">Great!</h2>
      <p className="lead">{message ? message : "Thank you for your submission."}</p>
    </div>
   {showTimer ? <div className="footer animated slow fadeInUp">
      <p id="timer">
     <h6>Redirecting in {timer} seconds...</h6> 
      </p>
    </div> : ""}
  </div>
  <br />
  <hr className="hr" />
  <div className="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
    <img
      src={logo}
      width={180}
    />
    <a
      href="https://knowledge.interface.re/fub-embedded-app-documentation"
      target="_blank"
      className="text-decoration-underline text-dark mt-2"
    >
      Learn more about InterFace
    </a>
  </div>
</div>

  </>
)




























  
  // const Location = useLocation();
  // const getURLParams = new URLSearchParams(Location.search);
  // const [hostName, setHostName] = React.useState("");
  // const [timer, setTimer] = React.useState(15);
  // const navigate=useNavigate()
  

  // const get_hostname = () => {
  //   let url = window.location.host
  //   let hostname = ""
  //   if (url === "app.sandbox.datalabz.re") {
  //     hostname = `https://sandbox.datalabz.re/embeddedapp?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
  //   }
  //   else if (url === "app.test.datalabz.re") {
  //     hostname = `https://app.test.datalabz.re/embeddedapp?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
  //   }
  //   else if (url === "app.datalabz.re") {
  //     hostname = `https://datalabz.re/embeddedapp?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
  //   }
  //   else {
  //     hostname = `http://localhost:8000/embeddedapp?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`
  //   }
  //   //console.log("hostname", hostname)
  //   return hostname;
  // }

  // var count = 15; // Timer
  // const [countdown, setCountdown] = useState(count);
  //  let url = window.location.host
  //  //console.log("signature",getURLParams)


  //  useEffect(() => {
  //   const countdown = setInterval(() => {
  //     setTimer(prevTimer => prevTimer - 1);
  //   }, 1000);

  //   setTimeout(() => {
  //     clearInterval(countdown);
  //     const interFaceListUrl=get_hostname();
  //     // history.push('/');
  //     // window.location.redirect=get_hostname();
  //     window.location.replace(interFaceListUrl);
  //     // //console.log("hostName",get_hostname())
  //     // navigate(get_hostname())
      
  //   }, timer * 1000);

  //   return () => clearInterval(countdown);
  // }, []);



  // // useEffect(() => {
  // //   const timer = setInterval(() => {
  // //     setCountdown(prevCountdown => prevCountdown - 1);
  // //   }, 1000);

  // //   // Redirect after the specified time
  // //   const redirectTimer = setTimeout(() => {
  // //     clearInterval(timer);
  // //     //console.log("hostName",hostName)
  // //     // window.location.href =url == "localhost:3000" ? "http://localhost:3000/forms" :  `https://datalabz.re/interface_forms/interface_list_view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`;
  // //   }, count * 1000);

  // //   // Clear intervals on component unmount
  // //   return () => {
  // //     clearInterval(timer);
      
  // //     clearTimeout(redirectTimer);
  // //   };
  // // }, [ count]);
  // // //console.log("url",url,hostName)
  // // useEffect(() => {
  // //   if(url === "app.sandbox.datalabz.re"){
  // //     setHostName ( `https://sandbox.datalabz.re/interface_forms/interface_list_view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`)
  // //   }else if(url === "app.test.datalabz.re"){
  // //     setHostName (`https://app.test.datalabz.re/interface_forms/interface_list_view?context=${getURLParams.get("context")}&signature=${getURLParams.get("signature")}`)
  // //   }else if(url === "localhost:3000"){
  // //     setHostName (`http://localhost:3000/forms`)
  // //   }
  // // },[])

  // return (
  //   <div style={{textAlign:"center",fontSize:"50px"}}>
  //     <p>InterFace</p>
  //     <h1>Thank you for submitting the form!</h1>
  //     <h6>Redirecting in {timer} seconds...</h6>

  //     {/* <p id="timer">{countDown()}</p> */}
  //     {/* <a href={hostName}>Click Here</a> */}
  //   </div>
  // );

  // <Confetti
  // width={window.innerWidth || 300}
  // height={window.innerHeight || 200}
  // numberOfPieces={showIcon ? 200 : 0}
  // friction	={0.99}
  // gravity={0.1}
  

// />
};





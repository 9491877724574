import React, { useState } from "react";
import WelcomeImg from "../../assets/images/opp-group-1.png";
import CreateOppPipelinesGroup from "./CreateOppPipelinesGroup";

const OppGroupsWelcomePage = ({ refetch }) => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const handleCloseModal = () => {
    setShowCreateGroupModal(false);
  };

  return (
    <section className="d-flex justify-content-center">
      <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between col-xl-11 col-xxl-9 gap-5">
        <div className="d-flex flex-column">
          <span className="text-secondary small mb-4">
            Setup your Opportunity table
          </span>
          <h5 className="mb-2" style={{ lineHeight: "1.5" }}>
            Create opportunity groups
            <br /> Select one or more pipelines for your table set
          </h5>
          <p className="text-secondary">
            Grouping pipelines helps organize your opp table.
          </p>
          <h5
            className="d-flex align-items-center gap-3 text-decoration-underline mt-4"
            onClick={() => setShowCreateGroupModal(true)}
          >
            Get started
            <i className="iconify fs-3" data-icon="tabler-arrow-right"></i>
          </h5>
        </div>
        <img src={WelcomeImg} alt="" />
      </div>
      <CreateOppPipelinesGroup
        showCreateGroupModal={showCreateGroupModal}
        setShowCreateGroupModal={setShowCreateGroupModal}
        refetch={refetch}
        handleCloseModal={handleCloseModal}
        fromWelcomePage={true}
      />
    </section>
  );
};

export default OppGroupsWelcomePage;

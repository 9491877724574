import React, { useState, useRef, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import Trigger from "../triggers/Trigger";
import WorkflowHeader from "../../../components/WorkflowHeader";
import Actions from "../automation/Actions";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkflowHelper } from "../../../utils/WorkflowHelper";
import cloneDeep from "lodash/cloneDeep";
import { Loaderr } from "../../../components/Loaderr";
import { ErrorPage } from "../../../utils/ErrorPage";
import WorkflowTitle from "./WorkflowTitle";
import '../../../assets/css/automationBuilder.css'
import { Spinner } from "../../../assets/images/three-dots-loading";
import debounce from "lodash/debounce";

const WorkFlowSessionInfo = createContext();

const UpdateWorkflowTrigger = gql`
  mutation UpdateWorkflowTrigger(
    $workflow_id: String!
    $trigger_data: GraphQLJSON!
  ) {
    UpdateWorkflowTrigger(
      workflow_id: $workflow_id
      trigger_data: $trigger_data
    ) {
      statusCode
    }
  }
`;

// const UpdateWorkflowActions = gql`
//   mutation UpdateWorkflowActions(
//     $workflow_id: String!
//     $actions_info: GraphQLJSON!
//   ) {
//     UpdateWorkflowActions(
//       workflow_id: $workflow_id
//       actions_info: $actions_info
//     ) {
//       statusCode
//     }
//   }
// `;

const UpdateWorkflowActions = gql`
  mutation UpdateWorkflowActions($workflowId: String!, $actionsInfo: GraphQLJSON!) {
  UpdateWorkflowActions(workflow_id: $workflowId, actions_info: $actionsInfo) {
    test_session
    statusCode
    message
    workflow {
      actions {
        action_id
        next_action_ids
        next_acion_id
        meta_data
        data_mapper
        action_path
        api_name
        api_type
        automation_name
        test_status
        is_default
        platform
      }
      workflow_name
      workflow_description
      workflow_id
      trigger_id
      trigger_details {
        trigger_id
        platform
        type
        team_id
      }
      status
    }
  }
}
`;

const GETWORKFLOW = gql`
  query Workflow($workflowId: String!) {
    Workflow(workflow_id: $workflowId) {
      actions {
        action_id
        next_action_ids
        next_acion_id
        meta_data
        data_mapper
        action_path
        api_name
        api_type
        automation_name
        test_status
        is_default
        platform
      }
      workflow_name
      workflow_description
      workflow_id
      trigger_id
      status
    }
    TestSession(workflow_id: $workflowId) {
      statusCode
      message
      data
    }
  }
`;

const UpdateToggleButton = gql`
  mutation updateworkflowstatus($workflowId: String!, $status: Boolean!) {
    UpdateWorkflowStatus(workflow_id: $workflowId, status: $status) {
      statusCode
    }
  }
`;

function EditWorkflow() {

  // Sticky header logic
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 60) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // ----------------


  const { workflow_id } = useParams();
  const { loading: workflow_loading, data: workflow_data } = useQuery(GETWORKFLOW, {
    variables: { workflowId: workflow_id},
  });
  const [workflow_status, setWorkflowStatus] = useState(null);
  const [switch_disabled, setSwitchDisabled] = useState(null);
  const [workflowactions, setWorkflowactions] = useState(null);
  const [actionsCount, setActionsCount] = useState(null);
  const workflow_helper = useRef(new WorkflowHelper(cloneDeep(workflowactions), setWorkflowactions));
  const [session, setsession] = useState(null);
  const [triggerdetails, setTriggerDetails] = useState({});
  const old_trigger_id = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [selecttrigger] = useMutation(UpdateWorkflowTrigger);
  const [update_actions, { data: updatedata, loading: updating, error: updateerror }] = useMutation(UpdateWorkflowActions,
    {
      onCompleted: (data) => {
        if (data?.UpdateWorkflowActions?.statusCode === 200) {
          setsession(data?.UpdateWorkflowActions?.test_session);
        }
      },
    }
  );
  const [update_status, { loading: updatingStatus }] = useMutation(UpdateToggleButton, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      alert("Something went wrong. Please try again.");
    },
  });

  const debouncedUpdateActions = useRef(debounce((variables) => {
    update_actions({
      variables,
    });
  }, 1)).current;

  const updateSwitchStatus = async (status) => {
    if (status === workflow_status || (workflow_status === null && status === false)) {
      return
    }

    const resp = await update_status({
      variables: {
        workflowId: workflow_id,
        status: status,
      },
    });

    if (resp?.data?.UpdateWorkflowStatus?.statusCode === 200){
      setWorkflowStatus(status);
    }
    else if (resp?.data?.UpdateWorkflowStatus?.message) alert(resp?.data?.UpdateWorkflowStatus?.message);
    else alert("An unknown error occurred.");
  };

  useEffect(() => {
    if (
      triggerdetails.data_mapper?.trigger_id &&
      old_trigger_id.current !== triggerdetails.data_mapper?.trigger_id
    ) {
      selecttrigger({
        variables: {
          workflow_id: workflow_id,
          trigger_data: triggerdetails,
        },
      });

      old_trigger_id.current = triggerdetails.data_mapper?.trigger_id;
    }
  }, [triggerdetails, triggerdetails.data_mapper]);

  useEffect(() => {
    if (workflow_data?.Workflow?.actions) {
      setWorkflowactions(workflow_data?.Workflow?.actions);
    }
    if (workflow_data?.Workflow?.actions.length > 0) {
      setTriggerDetails(
        workflow_data?.Workflow?.actions.find((action) => action.action_id === "trigger")
      );
      old_trigger_id.current = workflow_data?.Workflow?.actions.find(
        (action) => action.action_id === "trigger"
      ).trigger_id;
    }
  }, [workflow_data?.Workflow?.actions]);

  useEffect(() => {
    if (workflow_data?.Workflow) {
      setWorkflowStatus(workflow_data?.Workflow?.status || false);
    }
  }, [workflow_data, workflow_data?.Workflow?.status]);

  useEffect(() => {
    if (switch_disabled === true && workflow_status !== null){
      updateSwitchStatus(false);
    }
  }, [switch_disabled, workflow_status])


  useEffect(() => {
    if (actionsCount == null) return;
    if (actionsCount < 2) {
      setSwitchDisabled(true);
      return;
    }
    if (session === null) return;
    const sessionKeysArray = Object.keys(session);
    const actionsArray = workflow_helper.current.get_actions_by_order();
  
    const areAllActionsTested = () => {
      if (session) {
        const sessionKeysInActionsArray = sessionKeysArray.filter(key =>
          actionsArray.some(action => action.action_id === key)
        );
        if (sessionKeysInActionsArray.length > 0) {
          return sessionKeysInActionsArray.every(
            key => session[key]?.action_status?.toLowerCase() === 'success'
          );
        }
      }
      return false;
    };
  
    const allActionIdsPresent = actionsArray.every(action =>
      sessionKeysArray.includes(action.action_id)
    );
  
    if (allActionIdsPresent) {
      setSwitchDisabled(!areAllActionsTested())
    } else {
      setSwitchDisabled(true);
    }
  }, [session, actionsCount]);

  useEffect(() => {
    if (workflowactions === null) return;
    workflow_helper.current = new WorkflowHelper(
      cloneDeep(workflowactions),
      setWorkflowactions
    );
    debouncedUpdateActions({
      workflowId: workflow_id,
      actionsInfo: workflow_helper.current.get_workflow(),
    });
    setActionsCount(workflow_helper.current.get_number_of_valid_actions())
  }, [workflowactions]);

  useEffect(() => {
    setsession(workflow_data?.TestSession?.data);
  }, [workflow_data?.TestSession?.data]);

  if (updateerror !== undefined) return <ErrorPage updateerror={updateerror} />;
  if (workflow_loading) return <Loaderr />;

  const handleSave = () => {
    workflow_helper.current = new WorkflowHelper(
      cloneDeep(workflowactions),
      setWorkflowactions
    );
    update_actions({
      variables: {
        workflowId: workflow_id,
        actionsInfo: workflow_helper.current.get_workflow(),
      },
    });
  };
  const handelModal = (event) => {
    event.preventDefault();

    if (event.target === event.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const switchStatus = () => {

    if (actionsCount < 2) {
      return (
        <p className="switch-status text-danger small">
          Please add an action to turn on the workflow.
        </p>
      );
    }
    if (workflow_status) {
      return <p className="switch-status small">Workflow is turned on.</p>;
    }

    let switchStatus;
    if (switch_disabled) {
      switchStatus = (
        <p className="switch-status text-danger small">
          You have to test all actions successfully.
        </p>
      );
    } else {
      switchStatus = (
        <p className="switch-status text-success small">
          All actions are tested successfully.
        </p>
      );
    }
    return switchStatus;
  };

  

  return (
    <>
      <WorkflowHeader
        updatedata={updatedata}
        updating={updating}
        updateerror={updateerror}
        handleSave={handleSave}
      />
      <div
        className="main container mx-auto mb-4"
        onClick={(event) => {
          handelModal(event);
        }}
        id="body-pd"
      >
        <section className="col-xl-7 mx-auto position-relative">
          <div
            className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
            role="alert"
          >
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex align-items-center my-2">
                <i className="bx bx-mobile fs-5" />
                <i className="bx bx-arrow-to-right me-2 fs-5" />
                <i className="bx bx-desktop me-2 fs-5" />
              </div>
              <span className="my-2">
                Please switch to desktop and optimize this view. Smaller screens
                are not efficient.
              </span>
            </div>
          </div>
          
          <div className={`d-flex gap-2 justify-content-between mb-5 wrk-header ${isSticky ? "is-sticky col-12" : ""}`} ref={headerRef}>
            <WorkflowTitle workflowDetails={workflow_data?.Workflow} />

            <div className="d-flex flex-column align-items-end justify-content-between">
              { updatingStatus ? (
                <div className="react-loader">
                  {/* <i className="bx bx-loader-circle fs-3"></i> */}
                  { Spinner }
                </div>
              ) : (
                <div className="react-switch m-0 mt-1 d-flex justify-content-end">
                  <Switch
                    checked={workflow_status} // disable && runWorkflow && actionsCount > 1
                    width={40}
                    className="switch-rect"
                    height={20}
                    offstyle="primary"
                    disabled={switch_disabled} // disable && !runWorkflow && actionsCount < 2
                    onChange={(e) => {
                      if(!switch_disabled) {
                        updateSwitchStatus(e)
                      }
                    }}
                  />
                </div>
              )}
              {
                switchStatus()
              }
            </div>

          </div>

          <WorkFlowSessionInfo.Provider
            value={{
              session,
              isModalOpen,
              setIsModalOpen,
              setsession,
              workflow_helper,
              triggerdetails,
              formDetails,
              setFormDetails,
              updating
            }}
          >
            {workflow_data?.Workflow?.actions &&
              triggerdetails.data_mapper?.trigger_id ? (
              <Actions
                workflow_helper={workflow_helper}
                triggerdetails={triggerdetails}
                setTriggerDetails={setTriggerDetails}
                setActionsCount={setActionsCount}
              />
            ) : (
              <Trigger
                triggerdetails={triggerdetails}
                setTriggerDetails={setTriggerDetails}
              />
            )}
          </WorkFlowSessionInfo.Provider>
        </section>
      </div>
    </>
  );
}

export default EditWorkflow;
export { WorkFlowSessionInfo };

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { FormEntryDataTable } from "./FormEntryDataTable";
import { Loaderr } from "../../../components/Loaderr";
import timeConverter from "../../../utils/UnixTimeStamConverter";

// GraphQL Query
const GET_FORM_DATAS = gql`
  query FormData($formId: String, $formEntryId: String) {
    formData(form_id: $formId) {
      form_name
      form_id
      form_fields
    }
    formEntry(form_entry_id: $formEntryId) {
      form_entry_id
      form_id
      form_entry_data
      entry_created_date
    }
  }
`;

function FormEntryData() {
  const { id, entryid } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_FORM_DATAS, {
    variables: { formId: id, formEntryId: entryid },
  });

  if (loading) return <Loaderr />;
  if (error) return <div>Error loading form data.</div>;

  const { formData, formEntry } = data;

  return (
    <FormEntryDataUI
      navigate={navigate}
      formData={formData}
      formEntry={formEntry}
    />
  );
}

function FormEntryDataUI({ navigate, formData, formEntry }) {
  const [formEntryData, setFormEntryData] = useState({ formEntry });

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormEntryData((prev) => ({
      ...prev,
      formEntry: {
        ...prev.formEntry,
        form_entry_data: [
          {
            ...prev.formEntry.form_entry_data[0],
            [id]: value,
          },
        ],
      },
    }));
  };

  const entryDate = timeConverter(formEntry?.entry_created_date).split(",");
  const entryDateDisplay = entryDate[0];
  const entryTimeDisplay = entryDate[1];

  return (
    <div className="main fmc-main p-0">
      <section className="container-lg container-fluid pt-5">
        <b
          className="text-secondary pointer"
          onClick={() => navigate(`/forms/entries/${formEntry?.form_id}`)}
        >
          <i className="iconify me-2" data-icon="mdi:arrow-left" />
          Back to Form Entries
        </b>

        <div className="p-4 bg-white border rounded-3 mb-3 mt-3 d-flex justify-content-between align-items-center">
          <span>
            <h5>
              <i className="iconify me-2" data-icon="tdesign:form" />
              {formData?.form_name}
            </h5>
            <hr className="hr op-10" />
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Entry Date:</span>
              {entryDateDisplay}
            </p>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Entry Time:</span>
              {entryTimeDisplay}
            </p>
          </span>
        </div>

        <section className="d-flex flex-column py-4 gap-3">
          <FormEntryDataTable
            formEntryData={formEntryData}
            formData={formData}
            handleInputChange={handleInputChange}
            setFormEntryData={setFormEntryData}
          />
        </section>
      </section>
    </div>
  );
}

export default FormEntryData;


import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Loaderr } from '../../components/Loaderr';
import "../../../src/assets/css/embeddedapp.css"
import { NavLink } from 'react-router-dom';

const LeadForms=gql`
query FormNamesForleadForm($teamId: String) {
  formNamesForleadForm(team_id: $teamId) {
    form_name
    form_id
  }
}`
const GetTeamIdByFubdomain = gql`
query GetTeamIdByFubdomain($fubDomain: String) {
  GetTeamIdByFubdomain(fub_domain: $fubDomain) {
    team_id
  }
}
`

//console.log("Location1:", window.location.href)
export const CreateLeadForm = () => {
  const [domain, setDomain] = React.useState(null)
  const [personId, setPersonId] = React.useState(null)
  const [dropdownvalue, setDropdownvalue] = useState(null)
  const navigate = useNavigate()
  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);
  const context = getURLParams.get('context')
  const signature = getURLParams.get('signature')
  const [Formloading, setFormLoading] = useState(false);  // Add a loading state
  

 
  useEffect(() => {
    if (context) {
      const temp = atob(context)
      const object = JSON.parse(temp)

      if (object['account'].domain) {
        setDomain(object['account'].domain)
      }
      if (object['person'].id) {
        setPersonId(object['person'].id)
      }
    }
  }, [context])
  const handleChange = (e) => {
    setDropdownvalue(e.target.value)
  
  }


  const { data: TeamDataByFubDomain, loading: TeamDataLoading } = useQuery(GetTeamIdByFubdomain, {
    variables: { fubDomain: domain },
  })
  const { data: LeadFormData, loading: LeadFormLoading } = useQuery(LeadForms, {
    variables: { teamId: TeamDataByFubDomain?.GetTeamIdByFubdomain[0]?.team_id },
  })


  const HanndleGoback = () => {
    navigate(`/List-view?context=${context}&signature=${signature}`)
  }

  const handlePreviewClick = () => {
    //console.log("Clicked")
    navigate(`/forms/${dropdownvalue}?context=${context}&signature=${signature}&InputField-Follow-up-boss-lead-id=${personId}`)
  }


if (TeamDataLoading || LeadFormLoading) return <Loaderr />

  return (
    <div> <div id="loader" style={{ display: "none" }}>
    </div>
      <div id="demo" className="p-4 bg-white">
        <div
          className="d-flex justify-content-between align-items-center"
          id="back_button"
        >
          <img
            src="https://fs.hubspotusercontent00.net/hub/21128156/hubfs/Interface-dark-logo.png?width=108&height=108"
            width={45}
            alt='logo'
          />
          <b className="text-decoration-underline text-dark pointer">
            <i
              className="iconify"
              data-icon="material-symbols:arrow-back-rounded"
            ></i>
            <a
              onClick={() => { HanndleGoback() }} >
              Go back
            </a>
          </b>
        </div>
        <hr className="hr" />
        <div className="d-flex justify-content-center text-center mb-2">

          <b className="fs-6 text-center">Create New Lead</b>

        </div>
        <form method="POST" className="mb-5">

          {false ? (<div className="d-flex justify-content-center pb-4">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>) : (<div className="mb-4 mx-auto col-11">
            <label className='text-center w-100'>
              <span class=" mb-2 d-flex align-items-center justify-content-center">
                <span>Select a Lead Form</span>
              </span>
            </label>
            <select className="form-select input-bn" id="form_url" name="leadform" 
            value={dropdownvalue}
            onChange={(e)=>{handleChange(e)}}  >
              <option selected hidden>Select Lead Form</option>
              {
                LeadFormData?.formNamesForleadForm?.map((item) => {
                  return (
                    <option value={item.form_id}>{item.form_name}</option>
                  )
                })
              }
            
            </select>

          </div>

          )}



          





          <div className="d-flex">
            <div className="form-actions col form-group text-center">
              <p
                href="" className="btn-link" id="pipeline-link-btn">
                <button
                  onClick={() => { handlePreviewClick() }}
                  id="form-btn"
                  className="btn btn-primary col-8 center-btn"
                  type="button"
                  disabled={dropdownvalue == null ? true : false}
                >
                  Take me to the form
                </button>
                <NavLink 
                  to={dropdownvalue ? `/forms/${dropdownvalue}?context=${context}&signature=${signature}&InputField-Follow-up-boss-lead-id=${personId}` : "#"}
                  target="_blank"
                  rel="noreferrer"
                  className={`w-500 ${dropdownvalue == null ? "disabled-link" : ""}`}
> 
                  <span >
                <i
                    className="iconify mx-1 pointer text-secondary fs-5"
                    data-icon="fa:external-link"
                    title='Take me to the form'


                />
                  </span>
                </NavLink>
              </p>
            </div>
          </div>
        </form>
        <hr className="hr col-12" />
        <div className="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
          <img
          alt="Interface Logo"
            src="https://datalabz.re/static/images/interface_logo.png"
            width={180}
          />
          <a
            href="https://knowledge.interface.re/fub-embedded-app-documentation"
            target="_blank"
            className="text-decoration-underline text-dark mt-2"
          >
            Learn more about InterFace
          </a>
        </div>
      </div>
    </div>
  )
}
import { useContext, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Element from "./BoardElement";
import { boardConetxt } from "../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./form-builder-components/BoardElementsUI/BoardElementsSettingsUi/GetStyleClasses";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useFormApiHelper from "../utils/useFormApiHelper";
import { FormPlanContext } from "../pages/form-builder/dashboard/ContextHelper";
const getMapping = gql`
query GetFieldMappings {
  GetFieldMappings {
    id
    interface_field_id
    platform
    sync_direction
    platform_resource_type
    platform_field_id
  }
}`
const UpdateFormFields = gql`
mutation UpdateField($fieldId: String, $formId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, form_id: $formId, field_data: $fieldData) {
    field_id
  }
}`
const GetFieldsByTeamId = gql`
query GetFieldsByTeamId {
  GetFieldsByTeamId {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

function DropBoard({ getFormData, setDeleteField, formStatus,IsaList,osaList,AdminList }) {
  const ref = useRef();
  const params = useParams();
  const [Elementype, setElementype] = useState();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);
  // const { data: getMappingData } = useQuery(getMapping);
  // //console.log("getMappingData", getMappingData);
  // const [UpdateField] = useMutation(UpdateFormFields);
  const { formPlan } = useContext(FormPlanContext)
  const { board, setboard } = useContext(boardConetxt);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const { GetFormData, UpdateForm } = useFormApiHelper();
  const { updateForm } = UpdateForm();

  const { formDataLoading, formData } = GetFormData(params);
  useEffect(() => {
    setboard(() => formData?.formData?.form_fields);
  }, [formData?.formData,]);
  const { data:TeamData,loading:TeamFieldsLoading } = useQuery(GetFieldsByTeamId, {
    onCompleted: (data) => {
      ////console.log("data", data);
    }
  });

 // Update form when board or formStatus changes
 useEffect(() => {
  if (board?.length > 0) {
    // Create a map to store unique items by their elementid
    const uniqueItemsMap = new Map();

    // Iterate through the board and add only unique elementid items to the map
    board.forEach(item => {
      if (!uniqueItemsMap.has(item.elementid)) {
        uniqueItemsMap.set(item.elementid, item);
      }
    });

    // Convert the map values to an array to get the list of unique items
    const uniqueFormFields = Array.from(uniqueItemsMap.values());
    //console.log("uniqueFormFields",uniqueFormFields,uniqueFormFields.map(item => item.elementid));

    updateForm({
      variables: {
        formId: params.id,
        formData: {
          ...formData?.formData,
          form_fields: uniqueFormFields.map(item => item.elementid),
          form_conditions: getFormData?.form_conditions,
          form_name: getFormData?.form_name,
          form_description: getFormData?.form_description,
          status: formStatus,
          field_settings_data: uniqueFormFields,
          pipeline: getFormData?.pipeline,
          stage: getFormData?.stage,
        },
      },
    });
  }
}, [board, formStatus, IsaList,osaList,AdminList]);

 

  useEffect(() => {
    if (board?.length >= formPlan?.FieldLimit) {
      //console.log("boardCallling", board)
      setIsDragDisabled(true)
      // alert("You have reached the limit of fields")
    } else {
      setIsDragDisabled(false)
    }
  }, [board, formPlan?.FieldLimit]);

  // //console.log("PlanDetails", formPlan)



  let SaveStatus;
  if (formDataLoading) {
    SaveStatus = (
      <span
        className="text-secondary d-flex align-items-center"
        style={{ fontSize: "11px" }}
      >
        {" "}
        <i className="bx bx-loader-circle fs-4"></i>{" "}
        <span className="d-none d-lg-block">Saving</span>
      </span>
    );
  } else {
    SaveStatus = (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>{" "}
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  }
  useEffect(() => {
    const oppkeyId = "InputField-opp-key";
    const leadId = "InputField-Follow-up-boss-lead-id";
    const dispoId="InputField-Appt-form-entry-id"
    if (board && board.length > 0) {
      const oppkeyIndex = board.findIndex((e) => e?.elementid === oppkeyId);
      const leadIndex = board.findIndex((e) => e?.elementid === leadId);
      const dispoIndex = board.findIndex((e) => e?.elementid === dispoId);
      if (oppkeyIndex !== -1 && leadIndex !== -1 && dispoIndex !== -1) {
        const filteredBoard = board.filter(
          (e) => e?.elementid !== oppkeyId && e?.elementid !== leadId && e?.elementid !== dispoId
        );
        const updatedBoard = [
          ...filteredBoard,
          board[oppkeyIndex],
          board[leadIndex],
          board[dispoIndex],
        ];
        if (JSON.stringify(board) !== JSON.stringify(updatedBoard)) {
          let uniqueData = Array.from(
            updatedBoard.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
          );
          setboard(uniqueData);
          // setboard(updatedBoard);
        }
      }
    }
    
  }, [board]);

  return (
    <>

      <div className="container col-xl-10 mx-auto bg-white p-4 p-xl-5 mb-5 rounded-3 border " >
        <div className="w-100 d-flex flex-wrap justify-content-end m-0 mb-3">
          <p className="w-100">{isDragDisabled ?
            <div className="limit-alert w-100 my-3" >
              <div className="limit-icon">
                <i className="iconify" data-icon="dashicons:lock" ></i>
              </div>
              You are Currently on {formPlan.planName} Plan ,  You Reached the Field Limit Please Upgrade Plan
            </div>

            : null}
          </p>
          <span>{board?.length ? SaveStatus : ""}</span>
        </div>
        <div
          // className="mx-auto bg-white p-4 p-xl-5 mb-5 rounded-3 border"
          ref={ref}
        //  className= "disable-default-field"
        >
          <Droppable droppableId="dropboard" >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}
                style={{ minHeight: "400px" }}
              >
                <div className="container px-0">
                  <div className="row align-items-end">
                    {board?.length > 0
                      ? board.map((element, index) => {
                        ////console.log("DropItem",element);
                          const type=TeamData?.GetFieldsByTeamId?.find((item)=>item.field_id===element?.elementid)?.field_type
                        return (
                          <Draggable
                            key={String(element?.elementid)}
                            draggableId={String(element?.elementid)}
                            index={index}
                          >
                            {/* className="col-md-6"  */}
                            {(provided) => (
                              <div
                                className={GetStyleClasses(element.elementid) + "m-0 mb-2"}
                                // className="fm-comp"

                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Element element={element} setDeleteField={setDeleteField} IsaList={IsaList} osaList={osaList} AdminList={AdminList} Elementtype={type}/>
                                {/* {provided.placeholder} */}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                      : null}
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {board?.length > 0 && (
            <div className="d-flex justify-content-end">
              {/* <div
            className="d-flex align-items-center btn btn-primary"
            onClick={() => saveHandler()}
          >
            <span>Continue</span>
          </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DropBoard;

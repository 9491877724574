import React from "react";
import ArrPng from "../../../assets/images/arr.png";

export const Zapier = ({ action_index, action_info, setSelected_action }) => {
  return (
    <>
      <div
        className=""
        onClick={() => setSelected_action(action_info.action_id)}
      >
        <>
          <div className="d-flex justify-content-center">
            <img height="70" src={ArrPng} alt="" />
          </div>
          <div className="dot-border rounded-3 bg-white gx-2 mb-2 p-4 border position-relative">
            <div className="">
              <div className="w-100 mb-3">
                <h5 className="fs-6 m-0 mb-4 mt-2">
                  <strong> {action_index + ". "}</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 34 34"
                    className="iconify fs-6 me-1"
                  >
                    <path
                      fill="currentColor"
                      d="M20 16.005a9.6 9.6 0 0 1-.615 3.38a9.6 9.6 0 0 1-3.38.615h-.011a9.6 9.6 0 0 1-3.38-.615a9.6 9.6 0 0 1-.615-3.38v-.011c0-1.192.219-2.328.615-3.38a9.6 9.6 0 0 1 3.38-.615h.011c1.192 0 2.333.219 3.38.615a9.6 9.6 0 0 1 .615 3.38zm11.781-2.672h-9.344l6.599-6.604a16 16 0 0 0-1.724-2.041a16 16 0 0 0-2.041-1.729l-6.604 6.599V.219A17 17 0 0 0 16.006 0h-.016c-.907 0-1.792.083-2.656.219v9.344L6.735 2.964a15 15 0 0 0-2.047 1.724a15.5 15.5 0 0 0-1.729 2.041l6.599 6.604H.219S0 15.088 0 15.994v.011c0 .907.083 1.797.219 2.661h9.344l-6.599 6.599a15.8 15.8 0 0 0 3.765 3.776l6.604-6.599v9.339c.864.14 1.749.219 2.656.219h.021c.907 0 1.792-.079 2.656-.219v-9.339l6.599 6.599c.735-.5 1.417-1.083 2.041-1.719h.005a14.7 14.7 0 0 0 1.729-2.041l-6.599-6.599h9.339c.14-.86.219-1.74.219-2.641V16c0-.907-.079-1.792-.219-2.651z"
                    />
                  </svg>
                  Zapier
                </h5>
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
                  Webhook URL: {action_info?.data_mapper?.webhook_url}
                  <i
                    className="iconify fs-5"
                    data-icon="akar-icons:settings-horizontal"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Zapier;

import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spin } from "antd";
import OppPipelinesGroup from "./OppPipelinesGroup";
import CreateOppPipelinesGroup from "./CreateOppPipelinesGroup";

const GetOppPipelineGroups = gql`
  query GetOppPipelineGroups {
    GetOppPipelineGroups {
      opp_group_id
      opp_group_name
      pipeline_ids
      team_id
      is_default
    }
  }
`;

export const OpportunityPipelineGroups = () => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const {
    loading,
    data: oppPipelineGroups,
    refetch,
  } = useQuery(GetOppPipelineGroups);
  const handleCloseModal = () => {
    setShowCreateGroupModal(false);
  };

  if (loading)
    return (
      <div className="text-center gy-4 mt-5">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex flex-column">
            {/* <h5 className="m-0 mb-2">Opportunity settings</h5> */}
            <p className="small text-secondary">
              Manage and edit opportunity types by categorizing your piplines{" "}
            </p>
          </div>
          <div
            className="btn btn-primary"
            onClick={() => setShowCreateGroupModal(true)}
          >
            New Opportunity type
          </div>
        </div>
        <div className="row row-cols-2 gy-4">
          {oppPipelineGroups &&
            oppPipelineGroups.GetOppPipelineGroups.map((oppPipelineGroup) => (
              <OppPipelinesGroup
                key={oppPipelineGroup.opp_group_id}
                oppPipelineGroup={oppPipelineGroup}
                refetch={refetch}
              />
            ))}
        </div>
      </div>

      <CreateOppPipelinesGroup
        showCreateGroupModal={showCreateGroupModal}
        setShowCreateGroupModal={setShowCreateGroupModal}
        handleCloseModal={handleCloseModal}
        refetch={refetch}
      />
    </>
  );
};

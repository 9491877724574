import React, { useState } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import Select from "react-select";
import { Modal, Button, Alert } from "react-bootstrap";
import { Input, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";

const CreateOppPipelineGroup = gql`
  mutation CreateOppPipelineGroup(
    $oppGroupName: String!
    $pipelineIds: [String!]!
    $isDefault: Boolean
  ) {
    CreateOppPipelineGroup(
      opp_group_name: $oppGroupName
      pipeline_ids: $pipelineIds
      is_default: $isDefault
    ) {
      statusCode
      message
      data
    }
  }
`;

const GET_OPPORTUNITY_TYPES = gql`
  query OpportunityTypeByTeam {
    opportunityTypeByTeam {
      opp_type
    }
  }
`;

const CreateOppPipelinesGroup = ({
  showCreateGroupModal,
  handleCloseModal,
  refetch,
  fromWelcomePage = false,
}) => {
  const [createOppPipelineGroup, { loading: creatingOppGroup }] = useMutation(
    CreateOppPipelineGroup
  );
  const [opp_types, setOppTypes] = useState([]);
  const [opp_group_name, setOppGroupName] = useState("");
  const [is_default, setIsDefault] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: dataTypes } = useQuery(GET_OPPORTUNITY_TYPES, {
    notifyOnNetworkStatusChange: true,
  });
  const navigate = useNavigate();

  const handle_opp_type_options_change = (selectedOptions) => {
    setOppTypes(selectedOptions.map((option) => option.value));
  };

  const handleCreateOppGroup = () => {
    createOppPipelineGroup({
      variables: {
        oppGroupName: opp_group_name,
        pipelineIds: opp_types,
        isDefault: is_default,
      },
    })
      .then((response) => {
        const { statusCode, message } = response.data.CreateOppPipelineGroup;

        if (statusCode === 400) {
          setErrorMessage(message);
        } else {
          setErrorMessage("");
          handleCloseModal();
          if (fromWelcomePage) {
            navigate("/opp_group/success");
          }
          clearFields();
          refetch();
        }
      })
      .catch((error) => {
        console.error("Error creating opportunity group:", error);
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      });
  };

  const closeModal = () => {
    handleCloseModal();
    clearFields();
    setErrorMessage("");
  };

  const clearFields = () => {
    setOppGroupName("");
    setOppTypes([]);
    setIsDefault(false);
  };

  return (
    <>
      <Modal show={showCreateGroupModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Opportunity Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && ( // Conditionally render the alert
            <Alert
              variant="danger"
              onClose={() => setErrorMessage("")}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <div className="d-flex flex-column">
            <div className="mb-4">
              <label className="label mb-2">Opportunity Group</label>
              <Input
                className="form form-control input-bn"
                onChange={(e) => setOppGroupName(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="label mb-2">Select opportunity types</label>
              <Select
                value={opp_types.map((option) => ({
                  value: option,
                  label: option,
                }))}
                onChange={handle_opp_type_options_change}
                isMulti={true}
                options={dataTypes?.opportunityTypeByTeam
                  .filter((type) => type.opp_type !== "")
                  .map((type) => ({
                    value: type.opp_type,
                    label: type.opp_type,
                  }))}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select opportunity type"
                required
              />
            </div>

            <div className="mb-4">
              <Checkbox
                checked={is_default}
                onChange={(e) => setIsDefault(e.target.checked)}
              >
                Set as default group
              </Checkbox>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCreateOppGroup}
            disabled={creatingOppGroup || !opp_group_name || !opp_types.length}
          >
            {creatingOppGroup ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOppPipelinesGroup;

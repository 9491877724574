import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "../modules/MakeApiRequest";
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";
import { generatekeys } from "../utils/parser";

const TestGenericAction = ({ action_id, setTestAction, set_test_result, setShowDetails, selected_action, testAgain }) => {
  const { workflow_id } = useParams();
  const {session, setsession, workflow_helper} = useContext(WorkFlowSessionInfo);
  const [testActionOutput, setTestActionOutput] = useState( session ? session?.[action_id]?.action_output : []);
  const [outputArray, setOutputArray] = useState([]);
  const [testActionStatus, setTestActionStatus] = useState("");

  useEffect(() => {
    if(testAgain){
      makeApiCall("POST", "user/test_action/", {"action_id": action_id,
                                                  "workflow_id": workflow_id}).then((data) => {
      if(data?.action_output?.errorMessage){
        setTestActionOutput({ Error: data?.action_output?.errorMessage})
      }
      else if(data?.action_info?.error){
        setTestActionOutput({ Error: data?.action_info?.error})
      }
      else{
        setTestActionOutput(data?.action_output)
      }
      setTestActionStatus(data?.action_status);
      set_test_result(data?.action_status)
      setsession({...session, [action_id]: data })
    }
    );
  }
}, [testAgain]);

  const handleOutput = () => {
    let keys = generatekeys({[action_id] :testActionOutput})
    setOutputArray(keys?.[action_id])
  };

  useEffect(() => {
    handleOutput();
  }, [testActionOutput, selected_action, testAgain]);

  // function eachRecursive(data) {
  //   const items = [];
  //   const obj = JSON.parse(JSON.stringify(data));
  
  //   function traverse(obj, currentKey = '') {
  //     if (typeof obj === 'object' && obj !== null) {
  //       for (const key in obj) {
  //         const newKey = currentKey ? `${currentKey}.${key}` : key;
  //         if (typeof obj[key] === 'object' && obj[key] !== null) {
  //           if(obj[key] === true || obj[key] === false){
  //             obj[key] === true? obj[key] = "true": obj[key] = "false"}
  //           traverse(obj[key], newKey);
  //         } else {
  //           if(obj[key] === true || obj[key] === false ){
  //             obj[key].toString()
  //           }
  //           if(obj[key] === null){
  //             obj[key] = 'null';
  //           }
  //           items.push({ [newKey]: obj[key] });
  //         }
  //       }
  //     } else {
  //       items.push({ [currentKey]: obj });
  //     }
  //   }
  
  //   traverse(obj);
  //   setOutputArray(items);
  // }


  const testActions=()=>{
    let test="Loading...!"
  if(testActionStatus === "FAILED"){
    test= "Test failed!"
  }else if(testActionStatus===undefined){
    test="Test Failed Please Test Again"
 }
  else if(testActionStatus === "SUCCESS"){
    test= "Test successful!"
  }
  test = testAgain? test : session?.[action_id]?.action_status === "SUCCESS" ? "Test successful!" : "Test failed!"
  return test
}

  return (
    <>
        <>
          <div
            className="border rounded mt-3"
          >
          <div className="modal-header p-3 pb-0">
            <h6 className="modal-title fs-6">
              Test Details
            </h6>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={()=> {setTestAction("test_action")
              setShowDetails(false)
            }}
            ></button> */}
          </div>
            <div className="p-3">
              {/* <div className={`badge ${ testActions() === "Test successful!" ? "bg-success" : testActions() === "Loading...!" ? "bg-dark" : "bg-danger"} mb-2 fw-light rounded-pill px-3`}> */}
              <div className={`badge ${
                  (() => {
                      if (testActions() === "Test successful!") {
                          return "bg-success";
                      } else if (testActions() === "Loading...!") {
                          return "bg-dark";
                      } else {
                          return "bg-danger";
                      }
                  })()
              } mb-2 fw-light rounded-pill px-3`}>
               {testActions()}
              </div>
              {/* <p className="small text-secondary">
                This call was found in your account
                learn more about testing
              </p> */}
              {/* Using ternary operator */}
              {/* <div className="p-3 border bg-color-1 mb-3 rounded-3 text-trigger-box">
              {outputArray.length > 0 ? (
                <div className="m-0 ps-2 small border-start border-3 border-primary">
                 {
                   testActions() === "Loading...!" ? <div>Fetching data...</div> :
                 outputArray.map((item) =>
                      <p>{item.key_show + ": " + item.value}</p>  
                  )}
                </div>
                  ) : (
                    testActions() === "Loading...!" ? <div>Fetching data...</div> :
                    <div>Something went wrong.</div> 
                  )}
              </div> */}
              <div className="p-3 border bg-color-1 mb-3 rounded-3 text-trigger-box">
                {
                  outputArray.length > 0 ? (
                    <div className="m-0 ps-2 small border-start border-3 border-primary">
                      {
                        (() => {
                          if (testActions() === "Loading...!") {
                            return <div>Fetching data...</div>;
                          } else {
                            return outputArray.map((item) => (
                              <p>{item.key_show + ": " + item.value}</p>
                            ));
                          }
                        })()
                      }
                    </div>
                  ) : (
                    (() => {
                      if (testActions() === "Loading...!") {
                        return <div>Fetching data...</div>;
                      } else {
                        return <div>Something went wrong.</div>;
                      }
                    })()
                  )
                }
              </div>
              {/* <div className={`btn btn-primary ${testActionStatus !== "SUCCESS" && testActionStatus !== "FAILED" ? "disabled" : ""}`} onClick={()=> setTestAction("test_action_again")}>
                Continue
              </div> */}
            </div>
          </div>
        </>
    </>
  );
 }

export default TestGenericAction;

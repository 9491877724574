import { useContext, useEffect, useState } from "react";

import { TimePicker } from "antd";
import moment from 'moment'
import dayjs from "dayjs";



import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";
export const TimeUi = ({
  elementid,
  oppFields,

  showHide,
  previewFlag = false,
}) => {
  const { board, setboard } = useContext(boardConetxt);


  const [val, setval] = useState(null);
  const disableElementForBuild = window.location.pathname.includes("build")

  const {register,
    formState: { errors },
    control, setValue
  } = useFormContext({
    
  });


  // useEffect(() => {
  
  //     setValue(elementid, "date 01:20 am")
    

  //   // setValue(elementid, "11.02 am")
  //   // if (oppFields) {
  //   //   // setValue(elementid, `date ${oppFields}`);
  //   //   //console.log("oppFields", setValue(elementid, oppFields))
  //   // }
  // }, [oppFields, elementid, setValue])



  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const { label, instructions, required, hidden } = ElementSettingsData;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  if (val !== null) {
    var { $H, $M } = val;
  }
  const Times = `${$H}:${$M}`;
  const format = "HH:mm a";

  useEffect(() => {
    const newboard = board?.map((item) => {
      if (item?.elementid === elementid) {
        return { ...item, settings: { ...item.settings, Time: Times } };
      }
      return item;
    });
    setboard(newboard);

  }, [val]);
  //console.log("ElementSettingsData", oppFields)

 
  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const checkingValidation = () => {
    if (required === true && showHide === "show") {
      return false
    } else if (required === false && showHide === "show") {
      return false
    } else if (required === true && showHide === "hide") {
      return false
    } else if (required === false && showHide === "hide") {
      return false
    } else if (required === true &&  hidden === true) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) || ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating form-group">

        <Controller
          name={normalizeFieldName(elementid)}
          control={control}
          rules={{
            required: {
              value: checkingValidation(),
              message: "This Field is Required",
            }
          }}
          // defaultValue={oppFields}
          render={({ field }) => (
            <TimePicker
              className="form-control input-bn"
              format={format}
              disabled={disableElementForBuild}
              onChange={(date, dateString) => {
                ////console.log("date", dateString);
                field.onChange(dateString);
                setValue(elementid, dateString)
                setval(date);
              }}
              
            
              // field.onChange={(date, dateString) => {
              //   ////console.log("date", dateString);
              //   onChange(dateString);
              //   setval(date);
              // }}
              // onChange={(date, dateString) => {
              //   //console.log("date", dateString);
              //   field.onChange(dateString);
              //   setval(date);
              // }}
              id={normalizeFieldName(elementid)}
              defaultValue={oppFields == undefined ? val : dayjs(oppFields, format)}
             

              
              />
              
            )}
        />

        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="typcn:time" />
            <span>

              {label == undefined || label == "" ? ElementName : label}




              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>

      <small className="text-secondary">{instructions}</small>

      {/*  */}      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { Input, Checkbox } from "antd";

const UpdateOppPipelineGroup = gql`
  mutation UpdateOppPipelineGroup(
    $oppGroupId: ID!
    $oppGroupName: String
    $pipelineIds: [String]
    $isDefault: Boolean
  ) {
    UpdateOppPipelineGroup(
      opp_group_id: $oppGroupId
      opp_group_name: $oppGroupName
      pipeline_ids: $pipelineIds
      is_default: $isDefault
    ) {
      statusCode
      message
      data
    }
  }
`;

const GET_OPPORTUNITY_TYPES = gql`
  query OpportunityTypeByTeam {
    opportunityTypeByTeam {
      opp_type
    }
  }
`;

const UpdateOppPipelinesGroup = ({
  showUpdateGroupModal,
  handleCloseModal,
  oppPipelineGroup,
  refetch,
}) => {
  const [updateOppPipelineGroup, { loading: updatingOppGroup }] = useMutation(
    UpdateOppPipelineGroup
  );
  const [opp_types, setOppTypes] = useState(
    oppPipelineGroup?.pipeline_ids || []
  );
  const [opp_group_name, setOppGroupName] = useState(
    oppPipelineGroup?.opp_group_name || ""
  );
  const [is_default, setIsDefault] = useState(
    oppPipelineGroup?.is_default || false
  );
  const { data: dataTypes } = useQuery(GET_OPPORTUNITY_TYPES, {
    notifyOnNetworkStatusChange: true,
  });

  const handle_opp_type_options_change = (selectedOptions) => {
    setOppTypes(selectedOptions.map((option) => option.value));
  };

  const handleUpdateOppGroup = () => {
    updateOppPipelineGroup({
      variables: {
        oppGroupId: oppPipelineGroup?.opp_group_id,
        oppGroupName: opp_group_name,
        pipelineIds: opp_types,
        isDefault: is_default,
      },
    }).then(() => {
      handleCloseModal();
      refetch();
    });
  };

  return (
    <Modal show={showUpdateGroupModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update Opportunity Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="mb-4">
            <label className="label mb-2">Opportunity Group</label>
            <Input
              className="form form-control input-bn"
              value={opp_group_name}
              onChange={(e) => setOppGroupName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label className="label mb-2">Select opportunity types</label>
            <Select
              value={opp_types.map((option) => ({
                value: option,
                label: option,
              }))}
              onChange={handle_opp_type_options_change}
              isMulti={true}
              options={
                dataTypes?.opportunityTypeByTeam
                  .filter((type) => type.opp_type !== "")
                  .map((type) => ({
                    value: type.opp_type,
                    label: type.opp_type,
                  })) || []
              }
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select opportunity type"
              required
            />
          </div>

          <div className="mb-4">
            <Checkbox
              checked={is_default}
              onChange={(e) => setIsDefault(e.target.checked)}
            >
              Set as default group
            </Checkbox>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleUpdateOppGroup}
          disabled={updatingOppGroup || !opp_group_name || !opp_types.length}
        >
          {updatingOppGroup ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateOppPipelinesGroup;

import React, { useState } from "react";
import UpdateOppPipelinesGroup from "./UpdateOppPipelinesGroup";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";

const DeleteOppPipelineGroup = gql`
  mutation DeleteOppPipelineGroup($oppGroupId: ID!) {
    DeleteOppPipelineGroup(opp_group_id: $oppGroupId) {
      statusCode
      message
      data
    }
  }
`;

const OppPipelinesGroup = ({ oppPipelineGroup, refetch }) => {
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [deleteOppPipelineGroup, { loading: deletingOppGroup }] = useMutation(
    DeleteOppPipelineGroup
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseModal = () => {
    setShowUpdateGroupModal(false);
  };

  const handleDeleteOppPipelineGroup = () => {
    deleteOppPipelineGroup({
      variables: {
        oppGroupId: oppPipelineGroup?.opp_group_id,
      },
    }).then(() => {
      setShowDeleteModal(false);
      refetch();
    });
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center border gap-3 rounded-3 bg-white p-3">
          <div className="flex-column d-flex col">
            <span className="fs-6">{oppPipelineGroup?.opp_group_name}</span>
            <div className="d-flex flex-wrap gap-3 mt-3">
              {oppPipelineGroup?.pipeline_ids.map((pipeline_id) => (
                <span className="p-1 px-3 small rounded-2 pip-color">
                  {pipeline_id}
                </span>
              ))}
            </div>
          </div>
          <div onClick={() => setShowUpdateGroupModal(true)}>
            <i className="iconify fs-3" data-icon="tabler-edit"></i>
          </div>
          <div onClick={() => setShowDeleteModal(true)}>
            <i className="iconify fs-3" data-icon="tabler-trash"></i>
          </div>
        </div>

        <UpdateOppPipelinesGroup
          showUpdateGroupModal={showUpdateGroupModal}
          setShowUpdateGroupModal={setShowUpdateGroupModal}
          handleCloseModal={handleCloseModal}
          oppPipelineGroup={oppPipelineGroup}
          refetch={refetch}
        />
      </div>
      {/* Modal for confirmation */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete <b>{oppPipelineGroup?.opp_group_name}</b>{" "}
          opportunity group? This process cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleDeleteOppPipelineGroup}
            disabled={deletingOppGroup}
          >
            {deletingOppGroup ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OppPipelinesGroup;

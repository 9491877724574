import React, { useState, useEffect, useContext } from "react";
import { generatekeys_per_action } from "../../utils/parser";
import { WorkFlowSessionInfo } from "../../pages/automation-builder/workflows/EditWorkflow";
import { gql, useQuery } from "@apollo/client";
import CustomSelectField from "../../components/fields/CustomSelectField";
import SingleselecCustom from "../../components/fields/SingleselecCustom";
import { v4 as uuidv4 } from "uuid";
import FubDealDefaultFields from "./FubDealDefaultFields";
import { TypingDots } from "../../assets/images/three-dots-loading";

const CreateFubDeal = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  UpdateAction
}) => {
  const [options, setoptions] = useState([]);
  const GetFubAllData = gql`
    query GetFubAllData($type: String!) {
      GetFubAllData(type: $type) {
        info
        output
        status
      }
    }
  `;
  const { loading, data } = useQuery(GetFubAllData, {
    variables: { type: "dealcustomfields" },
  });

  const { loading: pipelinesLoading, data: pipelinesData } = useQuery(
    GetFubAllData,
    {
      variables: { type: "pipelines" },
    }
  );

  const pipelineOptions = pipelinesData?.GetFubAllData?.output.map(
    (pipeline) => ({
      key: pipeline.id,
      value: pipeline.id,
      keyShow: pipeline.name,
    })
  );
      
  const field = {
    key: {
      default_value: "people",
      readOnly: false,
      show: false,
      required: true,
      type: "string",
      name: "Custom Field Name",
      options: options,
    },
    value: {
      default_value: "people",
      readOnly: false,
      show: false,
      required: true,
      type: "string",
      name: "Custom Field Value",
    },
    status: {
      options: [
        { key: "Active", value: "Active", keyShow: "Active" },
        { key: "Archived", value: "Archived", keyShow: "Archived" },
      ],
    },
    pipeline: {
      options: pipelineOptions,
    },
    defaultKey: {
      name: "Field Name",
    }, 
    defaultValue: {
      name: "Field Value",
    },

  };

  const customfields = "createDeal";
  const [customFieldsMapper, setCustomFieldsMapper] = useState(
    data_mapper?.[customfields]
      ? data_mapper?.[customfields].map((item) => ({
          ...item,
          uuid: item.uuid || uuidv4(),
        }))
      : [
          { key: "pipeline", value: "", uuid: uuidv4() },
          { key: "stageId", value: "", uuid: uuidv4() },
          { key: "name", value: "", uuid: uuidv4() },
          { key: "peopleIds", value: "", uuid: uuidv4() },
          { key: "status", value: "", uuid: uuidv4() },
          { key: "price", value: "", uuid: uuidv4() },
          { key: "description", value: "", uuid: uuidv4() },
          { key: "commissionValue", value: "", uuid: uuidv4() },
          { key: "projectedCloseDate", value: "", uuid: uuidv4() },
        ]
  );

  const defaultFields = [
    "pipeline",
    "stageId",
    "name",
    "peopleIds",
    "status",
    "price",
    "description",
    "commissionValue",
    "projectedCloseDate",
  ];

  const { session } = useContext(WorkFlowSessionInfo);

  useEffect(() => {
    UpdateAction({
      data_mapper: {
        [customfields]: customFieldsMapper.map((field) => ({
          key: field.key,
          value: field.value,
        })),
      },
    });
  }, [customFieldsMapper]);

  useEffect(() => {
    let __options = [];
    let __data = data?.GetFubAllData?.output;
    __data?.map((item, index) => {
      __options.push({ key: item.name, value: item.name, keyShow: item.label });
    });
    setoptions(__options);
  }, [data?.GetFubAllData?.output]);

  if (loading || pipelinesLoading) return <div>Loading{ TypingDots }</div>;

  var keys = generatekeys_per_action(session);

  const handleDeleteField = (uniqueKey) => {
    setCustomFieldsMapper((prevFields) =>
      prevFields.filter((field) => field.uuid !== uniqueKey)
    );
  };

  const custom_fields = customFieldsMapper.map((value, index) => {
     if(!defaultFields.includes(value?.key)){
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"key"}
                  field={field["key"]}
                  field_options={field["key"].options}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <SingleselecCustom
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["value"]}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
            {index !== 0 && !defaultFields.includes(value?.key) && (
              <div className="p-0 mb-3 d-flex justify-content-end">
                <div
                  className="filt-del-fub"
                  onClick={() => handleDeleteField(value.uuid)}
                >
                  <i
                    className=" iconify fs-6 pointer text-danger"
                    data-icon="ep:circle-close-filled"
                  ></i>
                </div>
              </div>
            )}
          </div>
        </fieldset>
      );
    }
  });

  return (
    <>
      <p className="my-3">Default Fields</p>
      <FubDealDefaultFields 
        customFieldsMapper={customFieldsMapper}
        setCustomFieldsMapper={setCustomFieldsMapper}
        field={field}
        select_modal={select_modal}
        action_id={action_id}
        selected_modal_id={selected_modal_id}
        pipelinesData={pipelinesData}
        
        />
      <p>Custom Fields</p>
      {custom_fields}
      {/* {customFieldsMapper.length < 5 && ( */}
      <div
        className="btn btn-primary btn-sm"
        onClick={() => {
          //   if (customFieldsMapper.length < 5) {
          setCustomFieldsMapper([
            ...customFieldsMapper,
            { key: "", value: "", uuid: uuidv4() },
          ]);
          //   }
        }}
      >
        +
      </div>
    </>
  );
};
export default CreateFubDeal;

import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";

function Opportunity() {
  const param = useParams();
  const { GetOppByOppId } = useFormApiHelper();
  const { loading, error, data: oppEntryData } = GetOppByOppId(param.oppid);

  if (loading) return <Loaderr />;
  return <OppUi oppEntryData={oppEntryData} />;
}

function OppUi({ oppEntryData: oppData }) {
  const [editable, setEditable] = React.useState(true);
  const [oppEntryData, setOppEntryData] = React.useState(oppData);
  //console.log("oppEntryData", oppEntryData);
  const location = useLocation();
  const navigate = useNavigate();
  const { UpdateOpp } = useFormApiHelper();
  const { updateOpp, updateOppLoading, updateOppError, updateOppData } =
    UpdateOpp();

  const handleEdit = () => {
    setEditable(!editable);
  };
  const handelSave = () => {
    setEditable(!editable);
    updateOpp({
      variables: {
        formId: oppEntryData?.opportunity?.appt_form_id,
        oppKey: oppEntryData?.opportunity?.opp_key,
        oppData: oppEntryData?.opportunity,
      },
    });
  };

    const handleInputChange = (e) => {
      setOppEntryData(
        {
          ...oppEntryData,
            opportunity:
              {
                ...oppEntryData?.opportunity,
                [e.target.id]: e.target.value,
              },
        }
      );
  };

  return (
    <div>
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <b
            className="text-secondary pointer"
            onClick={() => navigate(`/opportunities/`)}
          >
            <i className="iconify me-2" data-icon="mdi:arrow-left" />
            Back to opportunity list
          </b>

          <div className="p-4 bg-white border rounded-3 mb-3 mt-3 d-flex justify-content-between align-items-center">
            <span>
              <h5>
                <i className="iconify me-2" data-icon="tdesign:form" />
                {/* {formEntry?.formEntry?.form_entry_id} */}
                {oppEntryData?.opportunity?.opp_key}
              </h5>
              <hr className="hr op-10" />
              <span className="text-secondary">
                Date created:{" "}
                {new Date(
                  oppEntryData?.opportunity?.opp_created_ts
                ).toLocaleString()}
              </span>
            </span>
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={() => (editable ? handleEdit() : handelSave())}
            >
              {" "}
              {editable ? "Edit" : "Save"}
            </button>
          </div>
          <section className="d-flex flex-column py-4 gap-3">
            <OppDataTable
              oppEntryData={oppEntryData}
              handleInputChange={handleInputChange}
              editable={editable}
            />
          </section>
        </section>
      </div>
    </div>
  );
}
const OppDataTable = ({ oppEntryData, handleInputChange, editable }) => {
  if (oppEntryData?.opportunity === null) return <h2>No opportunity Found</h2>;
  return (
    <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
      <div className="d-flex flex-column gap-5 mb-3 mt-3">
      {Object.keys(oppEntryData?.opportunity).map((key, index) => {
        const formattedKey = key
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        return (
          <div className="fs-6" key={index}>
            <div className="text-black-50 fw-bold">{formattedKey}</div>
            <hr className="hr op-10" />
            <input
              className="col-5 form-control"
              id={key}
              value={oppEntryData?.opportunity[key]}
              onChange={(e) => handleInputChange(e)}
              disabled={editable}
            />
          </div>
        );
      })}
      </div>
    </div>
  );
};
export default Opportunity;

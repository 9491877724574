import React from "react";
import CustomSelectField from "../../components/fields/CustomSelectField";
import SingleselecCustom from "../../components/fields/SingleselecCustom";

const FubDealDefaultFields = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  field,
  customFieldsMapper,
  setCustomFieldsMapper,
  pipelinesData,
}) => {

  const stageOptions = (pipelineId) =>
    pipelinesData?.GetFubAllData?.output
      .find((pipeline) => pipeline.id === pipelineId)
      ?.stages.map((stage) => ({
        key: `${stage.id}`,
        value: `${stage.id}`,
        keyShow: stage.name,
      })) || [];

  const priceKeyMapper = [{ key: "price", value: "price", keyShow: "Price" }];
  const descriptionKeyMapper = [{ key: "description", value: "description", keyShow: "Description" }];
  const projectedCloseDateKeyMapper = [{key: "projectedCloseDate", value: "projectedCloseDate", keyShow: "Projected Close Date"}];
  const peopleIdsKeyMapper = [{key: "peopleIds", value: "peopleIds", keyShow: "People Ids"}];
  const nameKeyMapper = [{key: "name", value: "name", keyShow: "Name"}];
  const CommissionKeyMapper = [{key: "commissionValue", value: "commissionValue", keyShow: "Commission"}];

  const fieldOptions = (field) => {
    if (field === "price") {
      return priceKeyMapper;
    } else if (field === "description") {
      return descriptionKeyMapper;
    } else if (field === "projectedCloseDate") {
      return projectedCloseDateKeyMapper;
    } else if (field === "peopleIds") {
      return peopleIdsKeyMapper;
    } else if (field === "name") {
      return nameKeyMapper;
    } else if (field === "commissionValue") {
      return CommissionKeyMapper;
    } 
  };


  const default_fields = customFieldsMapper.map((value, index) => {
    if (value.key === "status") {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={[
                    { key: "status", value: "status", keyShow: "Status" },
                  ]}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={field["status"].options}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    }
    if (value.key === "pipeline") {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={[{key: "pipeline", value: "pipeline", keyShow: "Pipeline Name" }]}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={field["pipeline"].options}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  field_name="pipeline"
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    } else if (value.key === "stageId") {
      const pipelineField = customFieldsMapper.find(
        (field) => field.key === "pipeline"
      );
      const stageOpts = stageOptions(pipelineField?.value);

      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={[
                    { key: "stageId", value: "stageId", keyShow: "Stage Name" },
                  ]}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={stageOpts}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                  field_name="stageId"
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    } else if (value.key === "price" || value.key === "description" || value.key === "projectedCloseDate" || value.key === "name" || value.key === "peopleIds" || value.key === "commissionValue") {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={fieldOptions(value.key)}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <SingleselecCustom
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={[]}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    }
  });

  return <>{default_fields}</>;
};
export default FubDealDefaultFields;

import React from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext } from "react";
import ConditionRuleStateUi from "./ConditionRuleStateUi";
import { useEffect } from "react";
export function ConditionRuleUi({
  ConditionRuleState,
  conditionIndex,
  handleConditionValueChange,
  removeConditionSet,
  addAndConditionRule,
  formdata,
  handleDataTypeChange,

}) {
  const { board } = useContext(boardConetxt);
  // const [hiddenValue, sethiddenValue] = useState(false);
  // const [filterFields, setfilterFields] = useState([]);

  // const [operator, setoperator] = useState("equals");

  let data_type = "";
  //console.log("ConditionRuleState>", board);
  // const filterFields = formdata.filter((field)=>{
  //   return field.elementid != "InputField-Follow-up-boss-lead-id" && field.elementid != "InputField-opp-key"
  // })

  // //console.log("filterFields",filterFields )
  // useEffect(() => {
  //   const filterFields = formdata.filter((field) => {
  //     return field.elementid !== "InputField-Follow-up-boss-lead-id" && field.elementid !== "InputField-opp-key"
  //   }
  //   )
  //   // setfilterFields(filterFields)
  // }, [formdata])



  useEffect(() => {

    if (ConditionRuleState) {
      let hidden = false;
      ConditionRuleState.map((item) => {
        if (item.field_id) {
          let field = formdata.find(
            (field) => field.elementid === item.field_id.replace(/[{}]/g, "")
          );
          if (field && field.settings.hidden) {
            hidden = true;
          }
        }
      });
      // sethiddenValue(hidden);
    }




  }, [ConditionRuleState])


  const conditionOperatorFunction = (type) => {
    //console.log("type", type)
    if (type === "date") {
      return (
        <>
          <option value="equals">On Date</option>
          <option value="not_equals">is not</option>

          <option value="startswith">After this date</option>
          <option value="endswith">Before this date</option>
        </>
      )

    } else if (type === "time") {
      return (
        <>
          <option value="equals">At Time</option>
          <option value="not_equals">is not</option>

          <option value="startswith">After this time</option>
          <option value="endswith">Before this time</option>
        </>
      )

    } else if (type === "numberfield") {
      return (
        <>
          <option value="equals">equals</option>
          <option value="not_equals">is not</option>
          <option value="greater_than">greater than</option>
          <option value="less_than">less than</option>
          <option value="greater_than_equal">greater than or equals</option>
          <option value="less_than_equal">less than or equals</option>
        </>
      )
    } else {
      return (
        <>
          <option value="equals">equals</option>
          <option value="not_equals">is not</option>
          <option value="contains">contains</option>
          <option value="startswith">begins with</option>
          <option value="endswith">ends with</option>
        </>
      )
    }

  }


  return (
    <>
      {ConditionRuleState?.map(
        (item, Ruleindex) => (
          (data_type = item.data_type),


          //console.log("ConditionRuleStateitem", item),
          (
            <div
              id={Ruleindex}
              key={Ruleindex}
              className="bg-white d-flex flex-wrap justify-content-between align-items-center p-4 px-xl-5 py-4 rounded-3 border position-relative"
            >
              <span onClick={() => removeConditionSet(conditionIndex, Ruleindex)}>
                <i
                  className="iconify text-secondary fs-6 fm-condi-close"
                  data-icon="mdi:close-circle"
                />
              </span>
              <div className="w-100">
                <div className="mt-0 mb-4">
                  <div className="d-sm-flex aligm-items-center ">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>IF</span>
                      </span>
                    </label>
                    <select
                      className="form-select input-bn"
                      name="field_id"
                      value={item.field_id}
                      required
                      onChange={(e) => {
                        handleConditionValueChange(e, Ruleindex, conditionIndex, true);
                        handleDataTypeChange(e, conditionIndex, Ruleindex);
                      }}
                    >
                      {/* <option value="">Please select a Option</option> */}
                      {/* <option value="" disabled selected hidden>Choose</option> */}
                      <option value="" selected hidden>Please select a Option</option>


                      {formdata?.map((field, key) => (
                        <option
                          value={field?.elementid}
                          data-fieldtype={field?.type}
                          key={key}
                          disabled={field?.elementid === "InputField-Follow-up-boss-lead-id" || field?.elementid === "InputField-opp-key" ? true : false}
                        >
                          {field?.settings?.label || field?.name || `<>Unlabelled Field<>`}
                        </option>
                      ))}
                    </select>
                  
                    {/* <span className="text-danger text-secondary small mt-2">{hiddneValueCheck ? "This field is hidden" : ""}</span> */}
                  </div>
                 
                </div>
                {
                  item?.field_id && formdata?.find((field) => field?.elementid === item?.field_id?.replace(/[{}]/g, ""))?.settings?.hidden ? (
                   
                      <span className="text-danger text-secondary small">This field is hidden</span>
                   
                  ) : ""
                }
              </div>
              <div className="w-100">
                <div className="mt-0">
                  <div className="d-sm-flex aligm-items-center mb-4">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>STATE</span>
                      </span>
                    </label>
                    <select
                      className="form-select input-bn"
                      name="operator"
                      required
                      onChange={(e) =>
                        handleConditionValueChange(e, Ruleindex, conditionIndex)
                      }
                      value={item.operator}
                    >
                      {/* <option value="">Please select a Option</option> */}
                      <option value="" disabled selected hidden>Choose</option>

                      {conditionOperatorFunction(data_type)}

                      {/* <option value="equals">equals</option>
                      <option value="not_equals">is not</option>
                      <option value="startswith">begins with</option>
                      <option value="endswith">ends with</option> */}
                    </select>
                    {/* <option value="in">contains</option> */}
                    {/* <option value="not_in">does not contain</option> */}
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="mt-0">
                  <div className="d-sm-flex aligm-items-center mb-4">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>VALUE</span>
                      </span>
                    </label>

                    <ConditionRuleStateUi
                      data_type={data_type}
                      board={formdata}
                      handleConditionValueChange={handleConditionValueChange}
                      Ruleindex={Ruleindex}
                      conditionIndex={conditionIndex}
                      item={item}
                      selectedElementId={ConditionRuleState[Ruleindex].field_id.replace(
                        /[{}]/g,
                        ""
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )
      )}
      {
        <>
          <input
            type="button"
            className="btn-check"
            name="and_or"
            id={conditionIndex}
          />
          <label
            className="btn btn-primary-outline small"
            htmlFor="and_select"
            onClick={() => addAndConditionRule(conditionIndex)}
          >
            AND
          </label>
          <hr />
        </>
      }
    </>
  );
}

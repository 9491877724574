import React, { useContext, useEffect } from "react";
import {
  boardConetxt,

} from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
export const RadiobuttonUi = ({ elementid, showHide, previewFlag = false, oppFields }) => {
  const { board } = useContext(boardConetxt);
  const { register,formState: { errors }, control, setValue } = useFormContext();
  const disableElementForBuild = window.location.pathname.includes("build");

  const ElementSettingsData = board.find(item => item?.elementid === elementid)?.settings || {};
  const { options, label, instructions, required, hidden } = ElementSettingsData;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);
//console.log("ElementSettingsData", oppFields)
  const ElementName = board.find(item => item?.elementid === elementid)?.name;

  const checkingValidation = () => {
    if (required && showHide === "show") {
      return false;
    } else if (!required && showHide === "show") {
      return false;
    } else if (required && showHide === "hide") {
      return false;
    } else if (!required && showHide === "hide") {
      return false;
    } else if (required && hidden) {
      return false;
    } else if (required) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  return (
    <div id={elementid} className={`${previewFlag && GetStyleClasses(elementid)} ${initStyle.display}`} hidden={hidden && previewFlag}>
      <label>
        {label || ElementName}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>

      {options?.length ? (
        options.map((item, index) => (
          <Controller
            key={index}
            name={normalizeFieldName(elementid)}
            control={control}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This field is required",
              },
            }}
            render={({ field }) => (
              <div className="mt-3" key={item.id}>
                <input
                  className="form-check-input"
                  type="radio"
                  {...field}
                  id={item.id}
                  disabled={disableElementForBuild}
                  value={item.value}
                  {...register(normalizeFieldName(elementid))}


/>
                <label className="form-check-label ms-2" htmlFor={item.id}>
                  {item.value}
                </label>
              </div>
            )}
          />
        ))
      ) : (
        <div className="mt-2">
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            render={({ field }) => (
              <input type="radio" className="form-check-input" {...field} disabled={disableElementForBuild} />
            )}
          />
          <label className="form-check-label text-secondary" htmlFor="check-1">
            Please Select an option or Add option
          </label>
        </div>
      )}

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors[elementid] && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
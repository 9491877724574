import React, { useEffect,useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {useNavigate} from 'react-router-dom'
import { useLocation } from "react-router-dom";
import {Loaderr} from '../../components/Loaderr'
import "../../../src/assets/css/embeddedapp.css"


const GetTeamIdByFubdomain = gql`
query GetTeamIdByFubdomain($fubDomain: String) {
  GetTeamIdByFubdomain(fub_domain: $fubDomain) {
    team_id
  }
}
`

const OpportunityByPerson = gql`query OpportunitiesByPersonId($fubPersonId: String, $teamId: String) {
  opportunitiesByPersonId(fub_person_id: $fubPersonId, team_id: $teamId) {
    opp_key
    opp_stage
    fub_deal_stage_name
    stage_id
    pipeline_id
    opp_type
    fub_person_id
    fub_deal_id
    teamFubDealId
    team
    sisu_client_id
    opp_address
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    otc_property_id
    opp_price
    opp_isa
    opp_forecasted_close_date
  }
}`


function GetForecastedClosedDate({ opp_forecasted_close_date }) {
  if (opp_forecasted_close_date){
    return (
    <p className="d-flex align-items-center m-0 p-0">
    <i className="iconify me-1" data-icon="fontisto:date" />
    {opp_forecasted_close_date}
  </p>
    );
}}


export const ListView = () => {
  const [domain, setDomain] = useState(null);
  const [personId, setPersonId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const context = urlParams.get('context');
  const signature = urlParams.get('signature');

  useEffect(() => {
    if (context) {
      const decodedContext = atob(context);
      const contextObject = JSON.parse(decodedContext);
      if (contextObject['account'].domain) {
        setDomain(contextObject['account'].domain);
      }
      if (contextObject['person'].id) {
        setPersonId(contextObject['person'].id);
      }
    }
  }, [context]);

  const { data: teamData, loading: teamLoading} = useQuery(GetTeamIdByFubdomain, {
    variables: { fubDomain: domain },
  });

  const { data: opportunitiesData, loading: opportunitiesLoading } = useQuery(OpportunityByPerson, {
    variables: {
      fubPersonId: String(personId),
      teamId: teamData?.GetTeamIdByFubdomain[0]?.team_id,
    },
  });

  const handleAddOpportunityClick = () => {
    navigate(`/createopportunity?context=${context}&signature=${signature}`);
  };

  const handleUpdateFormRedirect = (oppKey, stageId, pipelineId) => {
    navigate(`/updateopportunity?opp_key=${oppKey}&context=${context}&signature=${signature}&pipeline=${pipelineId}&stage=${stageId}`);
  };
  //console.log("opportunitiesData", opportunitiesData)
  const handleLeadFormsClick = () => {
    navigate(`/createleadform?context=${context}&signature=${signature}`);
  };

 

  const renderOpportunities = () => {
    if (
      !opportunitiesData?.opportunitiesByPersonId ||
      opportunitiesData.opportunitiesByPersonId.length === 0
    ) {
      return (
        <div className="d-flex mb-4 mt-4 flex-column justify-content-center align-items-center ">
          <img src="https://datalabz.re/static/img/empty-state.png" alt="No Opportunities" />
          <h5 className="fs-6 my-3 fw-bold">No Opportunities Found</h5>
          <b className="pointer text-decoration-underline text-primary justify-content-between">
            <button onClick={handleAddOpportunityClick}>
              <span className="oppstyle"><u>Add New+</u></span>
            </button>
          </b>
        </div>
      );
    } else {
      return opportunitiesData.opportunitiesByPersonId.map((opp) => (
        <div
          key={opp.opp_key}
          onClick={() => handleUpdateFormRedirect(opp.opp_key, opp.stage_id, opp.pipeline_id)}
          className="mb-4 card-chip custom-card-borderx rounded rounded-3 w-100 bg-white"
        >
          <div className="d-flex flex-wrap brdf bg-color-1 text-wrap w-100 px-3 py-3 border-bottom align-items-center justify-content-between">
            <b className="fs-6 m-0 fw-bold small oppstyle">{opp.fub_deal_stage_name}</b>
            <GetForecastedClosedDate opp_forecasted_close_date={opp.opp_forecasted_close_date} />
          </div>
          <div className="d-flex flex-column pt-3 pb-2 px-3 text-wrap w-100 justify-content-between">
            <div className="d-flex mb-3 flex-wrap w-100">
              <b className="fw-bold pe-2">{opp.opp_last_name}</b>
              <span>{opp.opp_address ? `: ${opp.opp_address}` : ''}</span>
              <span>{opp.fub_deal_id ? `: (${opp.fub_deal_id})` : ''}</span>
            </div>
            <div className="d-flex w-100 flex-wrap text-wrap justify-content-between">
              <span>{opp.opp_type}</span>
              <p className="m-0 p-0">
                <i className="iconify" data-icon="material-symbols:real-estate-agent-outline" />
                ISA: {opp.opp_isa ? ` ${opp.opp_isa}` : ' N/A'}
              </p>
            </div>
          </div>
          <hr className="hrx w-100" />
          <div className="sub-links d-flex pb-2 w-100 px-3 justify-content-between align-items-center">
            <h5 className="fw-bold fs-6 mb-2">{opp.opp_price ? `$${opp.opp_price}` : 0}</h5>
            {opp.otc_property_id !== null  ? null : (
              <a href="https://app.opentoclose.com/#projects/dashboard?type=properties" target="_blank" rel="noopener noreferrer">
                <img
                  className="add-on bg-white p-1 mx-2 rounded border mb-2"
                  height={30}
                  src="https://opentoclose.com/images/logo_purple.png"
                  alt="Open to Close"
                />
              </a>
            ) 
          }
          </div>
        </div>
      ));
    }
  };

  if (opportunitiesLoading || teamLoading) return <Loaderr />;

  return (
    <div>
      <div id="loader" style={{ display: 'none' }}></div>
      <section className="container p-0 px-2 pe-3 py-4  mx-auto my-auto" id="container">
        <div className="w-100" id="workoutListMainDiv">
          <div className="d-flex justify-content-between align-items-center gap-4 mb-4">
            <button className="btn btn-primary rounded-pill col" onClick={handleLeadFormsClick}>Lead Forms +</button>
            <button className="btn btn-primary rounded-pill col" onClick={handleAddOpportunityClick}>Opp Forms +</button>
          </div>
          <div className="mb-4 d-flex justify-content-between align-items-center create-btn">
            {/* <h5 className="p-0 m-0 fw-bold">Opportunities ({opportunitiesData?.opportunitiesByPersonId?.length || 0})</h5> */}
            <hr className="hr col" />
            {/* <button
              onClick={handleAddOpportunityClick}
              className="btn btn-lg btn-primary d-flex align-items-center justify-content-center shadow-add p-0 rounded-circle shadow-lg"
              style={{ width: '25px !important', height: '25px !important' }}
            >
              <i className="fs-5 iconify" data-icon="mdi:plus" />
            </button> */}
          </div>
          <div className="d-flex mb-4 flex-column justify-content-center align-items-center">
            {renderOpportunities()}
          </div>
          <hr className="hr col-12" />
          <div className="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
            <img src="https://datalabz.re/static/images/interface_logo.png" width={180} alt="Interface Logo" />
            <a
              href="https://knowledge.interface.re/fub-embedded-app-documentation"
              target="_blank"
              className="text-decoration-underline text-dark mt-2"
              rel="noopener noreferrer"
            >
              Learn more about InterFace
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
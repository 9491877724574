
function handleStringConditions(value1, value2, operator, caseInsensitive = false) {
    var supportedOperators = ["not_in", "in", "equals", "not_equals", "startswith", "endswith","contains"];

    var result = false;
    var info = "";
    if (!supportedOperators.includes(operator)) {
        info = "Operator: " + operator + " not supported";
        return { result: result, info: info };
    }

    value1 = convertToString(value1);
    value2 = convertToString(value2);
    if (value1 === null) {
        info = "Value1: " + value1 + " not a string and failed to convert";
        return { result: result, info: info };
    }
    if (value2 === null) {
        info = "Value2: " + value2 + " not a string and failed to convert";
        return { result: result, info: info };
    }

    value1 = caseInsensitive ? value1.toLowerCase() : value1;
    value2 = caseInsensitive ? value2.toLowerCase() : value2;
    switch (operator) {
        case "in":
            result = value2.includes(value1);
            break;
        case "not_in":
            result = !value2.includes(value1);
            break;
        case "equals":
            result = value1 === value2;
            break;
        case "not_equals":
            result = value1 !== value2;
            break;
        case "startswith":
            if (value2.startsWith(value1)) {
                result = true;
            }
            break;
        case "endswith":
            if (value2.endsWith(value1)) {
                result = true;
            }
            break;
        case "contains":
            if (value2.includes(value1)) {
                result = true;
            }
            break;
        default:
            result = false;
            info = "Operator: " + operator + " not handled";
    }
    return { result: result, info: info };
}

function convertToString(value) {
    if (typeof value == "string") {
        return value
    }
    else if (value !== null && value !== undefined) {
        return String(value)
    }
    else {
        return null
    }
}

function handleNumberConditions(value1, value2, operator) {
    var supportedOperators = ["equals", "not_equals", "greater_than", "less_than", "greater_than_equal", "less_than_equal"];
    //console.log("value1", value1, "value2", value2, "operator", operator)
    var result = false;
    var info = "";
    if (!supportedOperators.includes(operator)) {
        info = "Operator: " + operator + " not supported";
        return { result: result, info: info };
    }

    value1 = convertToNumber(value1);
    value2 = convertToNumber(value2);
    if (value1 === null) {
        info = "Value1: " + value1 + " not a number and failed to convert";
        return { result: result, info: info };
    }
    if (value2 === null) {
        info = "Value2: " + value2 + " not a number and failed to convert";
        return { result: result, info: info };
    }

    switch (operator) {
        case "equals":
            if (value2 !== 0) {
                result = value1 === value2;
                break;
            }
            break;
        case "not_equals":
            if (value2 !== 0) {
                result = value1 !== value2;
                break;
            }
            break;

        case "greater_than":
            //console.log("value1", value1, "value2", value2, result = value1 >= value2)
            if (value2 !== 0) {
                result = value1 > value2;
                break;
            }
            break;
        case "less_than":
            if (value2 !== 0) {
                result = value1 < value2;
                break;
            }
            break;
        case "greater_than_equal":
            if (value2 !== 0) {
                result = value1 >= value2;
                break;
            }
            break;
        case "less_than_equal":
            if (value2 !== 0) {
                result = value1 <= value2;
                break;
            }
            break;
        default:
            result = false;
            info = "Operator: " + operator + " not handled";
    }
    return { result: result, info: info };
}

function convertToNumber(value) {
    if (typeof value === 'number') {
        return value;
    }
    else if (typeof value === 'string') {
        return Number(value);
    }
    else {
        return null;
    }
}


export function get_fields_to_show(fields_data, conditions_info) {
    let fields_to_show = []
    let fields_to_hide = []
    // Loop conditions
    //console.log(fields_data, conditions_info)
    for (let i = 0; i < conditions_info?.length; i++) {
        let condition = conditions_info[i]
        let condtions = condition.condtions
        let Visibility = condition.Visibility
        let Fields = condition.Fields
        let or_result = false
        //console.log("condtions", condtions,)
        // Loop OR condtions
        for (let j = 0; j < condtions.length; j++) {
            let condtion = condtions[j]
            let and_result = true

            // Loop AND condtion
            for (let k = 0; k < condtion.length; k++) {
                let condtion_data = condtion[k]
                //console.log("condtion_data", condtion_data, condtion_data.data_type)
                let data_type = condtion_data.data_type
                let expected_value = condtion_data.expected_value
                let operator = condtion_data.operator
                let field_id = condtion_data.field_id

                if (data_type === 'text') {
                    let result_data = handleStringConditions(expected_value, fields_data[field_id], operator)
                    //console.log("result_data", result_data)
                    and_result = and_result && result_data.result
                }
                else if (data_type === 'number' || data_type === 'numberfield') {
                    let result_data = handleNumberConditions(expected_value, fields_data[field_id], operator)
                    //console.log("result_data", result_data)
                    and_result = and_result && result_data.result
                }
                else if (data_type === 'date') {
                    let result_data = handleStringConditions(expected_value, fields_data[field_id], operator)
                    and_result = and_result && result_data.result
                }
                else {
                    // default is text
                    let result_data = handleStringConditions(expected_value, fields_data[field_id], operator)
                    //console.log("result_data", result_data)
                    and_result = and_result && result_data.result
                }

                //console.log("and_result", and_result)
            }
            or_result = or_result || and_result
            if (or_result) {
                if (Visibility === 'show') {
                    fields_to_show = fields_to_show.concat(Fields)
                }
                else if (Visibility === 'hide') {
                    // fields_to_show = fields_to_show.filter(item => !Fields.includes(item))
                    fields_to_hide = fields_to_hide.concat(Fields)
                }
                // If any one of the OR condition is true then break the loop
                break
            }
        }
    }
    return [new Set(fields_to_show), new Set(fields_to_hide)]
}

// Testing
// let fields_data = {
//     "Email-50123848": "abc@abc.com",
//     "Email-509568323": "xyz@xyz.com",
//     "Email-50123844": "123"
// }
// let conditions = [
//     {
//         'condtions':
//             [
//                 [
//                     { 'data_type': 'text', 'expected_value': 'abc@abc.com', 'operator': 'equals', 'field_id': 'Email-50123848' },
//                     { 'data_type': 'text', 'expected_value': 'xyz@xyz.com', 'operator': 'equals', 'field_id': 'Email-509568323' },
//                     { 'data_type': 'number', 'expected_value': '123', 'operator': 'equals', 'field_id': 'Email-50123844' }
//                 ]
//             ],
//         'Visibility': 'show', 'Fields': ['DropDown-237078854', 'Email-50123849', 'asd'],
//         'ConditionName': 'conditionName'
//     },
//     {
//         'condtions':
//             [
//                 [
//                     { 'data_type': 'text', 'expected_value': 'abc@abc.com', 'operator': 'equals', 'field_id': 'Email-50123848' },
//                     { 'data_type': 'text', 'expected_value': 'xyz@xyz.com', 'operator': 'equals', 'field_id': 'Email-509568323' },
//                     // { 'data_type': 'text', 'expected_value': 'asd@asd.com', 'operator': 'equals', 'field_id': 'Email-50123844' }
//                 ],
//                 [
//                     { 'data_type': 'text', 'expected_value': 'asd@asd.com', 'operator': 'equals', 'field_id': 'Email-50123844' }
//                 ]
//             ],
//         'Visibility': 'hide', 'Fields': ['Email-50123849'],
//         'ConditionName': 'conditionName'
//     }
// ]
// let fields_to_show = get_fields_to_show(fields_data, conditions)
// //console.log(fields_to_show)